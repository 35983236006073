import { useEffect, useRef, useState } from "react";
import { solarPanelData, solarnetData, supportData } from "./SidePanelData";
import "./index.css";
import openClosePannelDrawer from "../../Images/sidenav/openClosePannelDrawer.svg";
import { Tooltip } from "@mui/material";
import CustomizePanelModal from "./CustomizePanelModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopup from "../uiComponents/DeletePopup";
import MagicLinkModal from "../uiComponents/magicLinkModal/MagicLinkModal";
import SearchIcon from "@mui/icons-material/Search";
import SearchPanelModal from "../uiComponents/searchPanelModal/SearchPanelModal";
import getLocalStorageData from "../../utils/getLocalStorageData";
import help_icon from "../../Images/help_icon.svg";
import { fetchHelpContent } from "../../redux/helpModalSlice";
import { useDispatch } from "react-redux";


const SidePanel = (props: any) => {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const [defaultPanelSearchValue, setDefaultPanelSearchValue] = useState("");
  const [selectedPanel, setSelectedPanel]: any = useState(null);


  useEffect(() => {

    setSelectedPanel(null)
  }, [props?.selectedStep]);

  const dispatch = useDispatch();



  //Not Completed Yet
  const handleDefaultPanelSearch = (value: any) => {
    setDefaultPanelSearchValue(value);
  };

  useEffect(() => {
    let timeoutID = setTimeout(() => {
      props.getDefaultPanelData(defaultPanelSearchValue);
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [defaultPanelSearchValue]);

  // useEffect(() => {
  //   console.log(props.editorMenuList,'props.editorMenuList ##');
  // }, [props.editorMenuList]);

  const sidePanelArr =
    props?.pannelType === "Solar Panel"
      ? [...solarPanelData, ...props.panels]
      : props?.pannelType === "Solar Net"
        ? props.editorNets
        : props.editorSupports
  // : supportData;




  const panelDeletePopupHandler = (item: any) => {
    setOpenDeletePopup(!openDeletePopup);
    setDeleteItem(item);
  };

  const onDeleteSubmit = () => {
    setOpenDeletePopup(!openDeletePopup);
    props.deletePanel(deleteItem);
  };

  //   useEffect(() => {
  //     if (props.threePointStart && props.movingSupportData && props.movingSupportData.id) {
  //       let arr =[...]
  // let selected = 
  //     }
  //     else {
  //       sidePanelArr = props.editorSupports
  //     }

  //   }, [props.threePointStart, props.movingSupportData]);


  const imgRef = useRef(null);
  const handleDragStart = (e: any) => {
    e.dataTransfer.setDragImage(imgRef.current, 0, 0);
  };

  return (
    <div
      className={`w-[150px] relative z-10 bg-[#f6f6f6] shadow-lg ${props.sidePanelRoot}`}
    >
      <div
        className={`flex h-[87vh] flex-col items-center gap-2 overflow-y-auto ${props?.pannelType === "Solar Net" && `gap-1`
          }`}
      >
        <>
          {/* new design on conflict need to fix */}
          {/* <div className='w-full justify-center flex gap-1'> */}
          {/* <ButtonFieldInput
                            buttonText={''}
                            name="+"
                            handleButton={props.handlePanelModal}
                            // startIcon={<img src={backIcon} width={9} height={9} alt="arrow" />}
                            buttonextracls={`!text-white text-xs !bg-[#1473E6] hover:bg-[#454545]  !rounded-full !font-bold font-[SansRegular] duration-500 cursor-pointer !w-[10%]`}
                        /> */}
          {/* {props?.pannelType === 'Solar Panel' &&
                            <Tooltip title='Customize your panel' placement='bottom'>
                                <div className='w-2/4 flex items-center justify-center m-2' onClick={props.handlePanelModal}>
                                    <p className='!text-white text-xl !bg-[#1473E6] flex justify-center items-center w-full p-1 !rounded-full !font-bold font-[SansRegular]  cursor-pointer '> */}
          {/* <img src={addPanel} alt='addPanel' width={30} height={30} /> */}
          {/* +
                                    </p>
                                </div>
                            </Tooltip>}
                    </div>
                    <div className='!max-h-[80vh] overflow-y-scroll scroll-smooth flex flex-col gap-4'>
                        {
                            sidePanelArr?.map((item: any, idx: number) => {
                                return (
                                    <div className={`${(idx === 2 || idx === 1) ? 'lastItem' : 'first-pannel'} ${props?.pannelType === 'Solar Net' && `!p-0 flex flex-col items-center normal-case w-fit border border-solid border-[#E0E0E0] rounded-lg`}`} key={idx} onClick={() => props.onClick(item)}>
                                        {item?.img ?
                                            <div className={`${props?.pannelType === 'Solar Net' && `w-32 h-auto flex flex-col items-center`}`}>
                                                <img src={item?.img} alt='img' className={`${item?.type === 'cornerSupport' || item?.type === 'sideSupport' ? `h-[1.5rem] w-[1.5rem] my-0 mx-auto ` : `h-[4rem] w-[4rem]`} ${props?.pannelType === 'Solar Net' && `!p-0 !h-full !w-full`}`} draggable={props.draggable} id={JSON.stringify(item)} />
                                                <p className={`${props?.pannelType === 'Solar Net' && `text-[14.702px] leading-[30.875px] !text-[#454545] font-[RubikRegular]`}`}>{item?.title}</p> */}
          {props?.pannelType === "Solar Panel" ? (
            <div className="flex w-full gap-1">
              {/* <div className='!text-[#fff] text-base !bg-[#777] !py-2 !px-6  !font-semibold font-[SansRegular] duration-500 cursor-pointer !w-[90%] rounded-tr-md rounded-br-md' onClick={props.handleClearPanelType}><p className='flex gap-4 items-center'><span><img src={backIcon} alt="backIcon" /></span></p></div> */}
              <div className="m-2 flex w-full items-center justify-center">
                <p className={`flex items-center justify-center gap-1 flex-grow`}>
                  {/* <Tooltip title='Customize your panel'> */}
                  <div
                    className="panel-add-button group relative"
                    onClick={props.handlePanelModal}
                  >
                    <span
                      className={`flex h-6 w-12 cursor-pointer items-center justify-center !rounded-full !bg-[#1473E6] px-4 py-2 font-[SansRegular] text-base !font-bold  !text-white `}
                    >
                      +
                    </span>
                    <span
                      className={`z-99 absolute -left-2 top-8 hidden w-32  rounded-xl border border-gray-400 bg-white p-1 pl-2 text-[10px] shadow-2xl transition-all group-hover:inline`}
                    >
                      Customize your panel
                    </span>
                  </div>
                  <div
                    className="default-panel-add-button group relative"
                    onClick={props.handleOpenDefaultPanelModal}
                  >
                    <span
                      className={`flex h-6 w-12 cursor-pointer items-center justify-center !rounded-full !bg-[#1473E6] px-4 py-2 font-[SansRegular] text-base !font-bold  !text-white `}
                    >
                      <SearchIcon sx={{ fontSize: "15px" }} />
                    </span>
                    <span
                      className={`z-99 absolute -left-12 top-8 hidden w-32  rounded-xl border border-gray-400 bg-white p-1 pl-5 text-[10px] shadow-2xl group-hover:inline`}
                    >
                      Search your panel
                    </span>
                  </div>
                  <div
                    className="group relative"
                    onClick={() => dispatch(fetchHelpContent({ keywordItem: 'panel_search', type: null }))}
                  >
                    <img src={help_icon} alt="help icon" className={`bg-gray-300 hover:bg-gray-500 rounded-full text-sm w-[18px] h-[18px] cursor-pointer`} />
                    <span
                      className={`z-99 absolute -left-12 top-8 hidden w-fit  rounded-xl border border-gray-400 bg-white p-1 text-[10px] shadow-2xl group-hover:inline`}
                    >
                      Know More
                    </span>
                  </div>
                  {/* </Tooltip> */}
                </p>
              </div>
            </div>
          ) : (
            <div className={`p-1`}></div>
          )}
          <div className="mb-3 flex h-full w-full flex-col gap-1 overflow-y-scroll scroll-smooth pb-8 p-3 py-1">
            {/* {props.addCornerSupport && <div className='text-center cursor-pointer'>
                            <p className={`text-sm`} onClick={() => props.onClick({ action: 'addSupport', type: 'cornerSupport' })}>Add Corner Support</p>
                        </div>} */}
            {sidePanelArr?.map((item: any, idx: number) => {
              let userId = getLocalStorageData("userId");
              // let isEditable = item?.users?.find((id: any) => {
              //   return userId === id;
              // });
              let role = localStorage.getItem("role");

              return (
                <div
                  id={item?.type + idx}
                  className={`${item?.type === "support" || item?.type === "net" ? "p-2" : ""} rounded-xl ${item?.type === "net" && (props.selectedNet?.title === item?.title) ? "border-[#2C71F6] border-[2px]" : null}`}
                  key={idx}
                  onClick={() => props.onClick(item)}
                >
                  {(item?.img || item?.image) && item?.type !== 'net' ? (
                    <div
                      className={`relative py-1 flex cursor-pointer flex-col items-center text-center
                        panel-${idx + 1
                        } ${item?.title && item?.title === props?.polyData?.title
                          ? `border border-black`
                          : ``
                        } ${item?.type === "threePointSupport" &&
                          props?.threePointStart
                          ? "rounded-md bg-[#E4E4E4] p-2"
                          : ""
                        }`}

                    >



                      {/* Know More Button */}
                      {item?.type !== 'panel' && <div className="absolute top-0 right-0  justify-start">
                        <img onClick={

                          (e) => { e.stopPropagation(); dispatch(fetchHelpContent({ keywordItem: item?.keyword, type: null })); }

                        } src={help_icon} alt="help_icon" className={`bg-gray-300 hover:bg-gray-500 rounded-full text-sm w-[20px] h-[20px]`} />

                      </div>}

                      <div className="flex flex-col items-center">
                        <img
                          ref={imgRef}
                          src={item?.img ? item?.img : `${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.image}`}
                          crossOrigin="anonymous"
                          alt="img"
                          draggable={item.draggable}
                          id={JSON.stringify(item)}
                          className={`${item?.type === "support" ? "w-[25px] h-[25px]" : "w-[70px] h-[70px]"}`}
                          width={1500}
                          height={1500}
                        />
                        <p
                          draggable={item.draggable}
                          onDragStart={handleDragStart}
                          id={JSON.stringify(item)}
                          className={`${item?.type === "support" || item?.type === "net" ? "text-[13px]" : "text-[12px]"}
                          ${item?.type === "support" ? "mt-1.5" : "mt-2"} font-medium flex flex-wrap`}
                        >
                          {item?.title ? item?.title : item?.name}
                        </p>
                      </div>



                    </div>
                  ) : (

                    <div className="group relative flex cursor-pointer flex-col items-center justify-center">
                      {item?.img &&
                        <>
                          <img
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.img}`} width={1500} height={1500}
                            alt="img"
                            className={`w-[70px] h-[70px] object-contain bg-white mt-2`}
                            draggable={item.draggable}
                            id={JSON.stringify(item)}
                          // onClick={() => props.onClick(item)}
                          />
                          <p className={`text-[13px] font-medium mt-2 text-center px-1`}>{item?.title ? item?.title : item?.name}</p>
                        </>

                      }
                      {props?.pannelType === "Solar Panel" && <p
                        className={`${item?.height > item?.width
                          ? `h-[5rem] w-[3rem]`
                          : `h-[3rem] w-[5rem]`
                          }`}
                        style={{
                          background: `${item?.fill ? item?.fill : "#013A7D"}`,
                        }}
                        draggable={props.draggable}
                        id={JSON.stringify(item)}
                      // onClick={() => props.onClick(item)}
                      ></p>}
                      {props?.pannelType === "Solar Panel" && <Tooltip
                        title={`${item?.panelWidthNew} mm x ${item?.panelHeightNew} mm`}
                        arrow
                        disableInteractive
                        placement={`right`}
                      >
                        <div className={`w-[5rem] text-center`}>
                          <p className={`truncate text-[13px] font-bold`}>
                            {item?.name}{" "}
                          </p>
                          <p className={`truncate text-[12px]`}>
                            {item?.panelWidthNew} mm x {item?.panelHeightNew} mm
                          </p>
                        </div>
                      </Tooltip>}
                      {props?.pannelType === "Solar Panel" && item?.users?.length > 0 && (item?.users?.indexOf(userId) > -1 || role === "admin") &&
                        <div
                          className={`absolute -top-4 ${item?.height > item?.width ? 'right-3' : '-right-1'} flex flex-col items-start justify-end`}
                        >
                          <div
                            className={`cursor-pointer p-2`}
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); panelDeletePopupHandler(item) }}
                          >
                            <DeleteIcon
                              style={{ height: "16px", width: "16px" }}
                              className="rounded-full text-[#2d2d2d] hover:bg-gray-500 hover:text-white bg-gray-300"
                            />
                          </div>

                          <div
                            className={`cursor-pointer p-2`}
                            onClick={(e) => {
                              e.stopPropagation(); e.preventDefault();
                              props.handlePanelEdit(item);
                            }}
                          >
                            <EditIcon
                              style={{ height: "16px", width: "16px" }}
                              className="rounded-full text-[#2d2d2d] hover:bg-gray-500 hover:text-white bg-gray-300"
                            />
                          </div>
                        </div>}
                      {/* <img src={editIcon} alt='edit-icon' style={{width: '12px', height: '12px'}} onClick={ ()=> {props.handlePanelEdit(item); }} /> */}
                    </div>



                  )}
                </div>
              );
            })}
          </div>
          {props.openPanelModal ? (
            <CustomizePanelModal
              open={props.openPanelModal}
              onClose={props.handleClosePanelModal}
              panelForm={props.panelForm}
              blockPickerColor={props.blockPickerColor}
              handleColorPicker={props.handleColorPicker}
              loader={props.loader}
            />
          ) : props.openMagicForm ? (
            <MagicLinkModal
              open={props.openMagicForm}
              onClose={props.closeSignupModal}
              magicLinkForm={props.magicLinkForm}
              magicFormBtnLoader={props.magicFormBtnLoader}
              handleCancelMagiModal={props.handleCancelMagiModal}
              title={`Congratulations`}
              description={`You have successfully created net(s), in order to save for future use, kindly enter your email. 
                                We will send you email with account details. if you do not want to save  in your account you can cancel this request.`}
            />
          ) : null}
          {props.isDefaultPanelModalOpen && (
            <SearchPanelModal
              loader={props.loader}
              defaultPanels={props.defaultPanels}
              open={props.handleOpenDefaultPanelModal}
              onClose={props.handleCloseDefaultPanelModal}
              handleDefaultPanelSearch={handleDefaultPanelSearch}
              defaultPanelSearchValue={defaultPanelSearchValue}
              addSelectDefaultPanels={props.addSelectDefaultPanels}
              selectedPanels={props.selectedPanels}
              setSelectedPanels={props.setSelectedPanels}
              title={`Panel Library`}
              panleLibLoginMsg={props.panleLibLoginMsg}
              handlePanleLibLoginMsg={props.handlePanleLibLoginMsg}
            />
          )}
          {openDeletePopup && (
            <DeletePopup
              dialogTitlecls={`!text-red-500`}
              submitBtnCls={`!bg-red-600`}
              open={openDeletePopup}
              title={`Delete`}
              handleClose={panelDeletePopupHandler}
              DialogContent={
                <>
                  <p>{"Are you sure, you want to delete this panel?"}</p>
                </>
              }
              DeleteBtn={"Delete"}
              onClick={onDeleteSubmit}
            />
          )}
        </>
      </div>
      <div
        className="absolute -right-5 top-[50%] z-10 flex h-12 w-6 cursor-pointer items-center justify-end rounded-l-md font-[SansRegular] !font-semibold duration-500 "
        onClick={props.handleClearPanelType}
      >
        <p className="flex items-center gap-4">
          <span>
            <img src={openClosePannelDrawer} alt="openClosePannelDrawer" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default SidePanel;
