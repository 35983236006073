import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
// import text from '../../../common/en_US.json'

const EmailConfirmationModal = (props: any) => {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* <Button variant="outlined" onClick={props.handleClickOpen}>
                Delete
            </Button> */}
            <Dialog
                // fullScreen={fullScreen}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={`!max-w-full !min-w-max h-auto m-0 ${"dilogBox_main"} confirmationModalWidth`}
                onClick={props.handleClickOpen}
            >
                <DialogTitle id="responsive-dialog-title" className={`flex justify-between items-center border-b border-slate-200 px-1.5 `}>
                    {props.modalText ? props.modalText : ""}
                    <CloseIcon onClick={props.handleClose} className={`cursor-pointer text-slate-500`} />
                </DialogTitle>
                <DialogContent className={`px-1.5 py-3 mt-4`}>
                    <DialogContentText >
                        {props.DilogContent ? props.DilogContent : ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={`px-1.5 py-3`}>
                    <Button autoFocus onClick={() => props.handleSave(false)} className={`capitalize font-normal text-slate-600 px-3 py-1.5`}>
                        No
                    </Button>
                    <Button onClick={() => props.handleSave(true)} autoFocus className={`capitalize font-normal border-solid border px-3 py-1.5 border-wv-buttonblue !text-white !bg-[#1473E6] hover:bg-[#1473E6] rounded`}>

                        {props.ConfirmBtn}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EmailConfirmationModal