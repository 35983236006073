import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./index.css";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import net from "../../Images/net 1.jpeg";
import net2 from "../../Images/net 2.jpeg";
import { array } from "yup";
import SideNav from "../../common/commonSideNav/SideNav";
import { ForCanvasEditor } from "./Hooks";
import SidePanel from "../../common/commonSideNav/SidePanel";
import Header from "../../common/commonHeader/Header";
import { AlignGuidelines } from "fabric-guideline-plugin";
import { useJwt } from "react-jwt";
// import { useNavigate } from "react-router";
import RightSideNav from "../../common/commonSideNav/rightSideNav";
import { navigateToGn } from "../../utils/navigateToGn";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TokenGet } from "../../utils/tokenGet";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { async } from "q";
import { doPutApiCall } from "../../utils/ApiConfig";
import Videomodal from "../../common/uiComponents/VideoModal/Videomodal";
import { sideNavData } from "../../common/commonSideNav/sideNavData";

const getQueryStringValue = (key: string) => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
        encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
        "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
};

const Editor = (props: any) => {
  const {
    pannelType,
    handlePannelType,
    onAddRectangle,
    clickAddNet,
    handleClearPanelType,
    onDeleteSelection,
    showDelete,
    onReady,
    editorMemo,
    editor,
    clearCanvas,
    saveCanvasData,
    updateZoom,
    onAddSupport,
    quoteData,
    openEdit,
    onQuoteNameChange,
    handleOpenEdit,
    selected,
    selectionType,
    enableDisableClickThrough,
    spanning,
    resetZoom,
    handleUndo,
    handleRedo,
    netData,
    handlePanelModal,
    openPanelModal,
    handleClosePanelModal,
    panelForm,
    customPanelArr,
    blockPickerColor,
    handleColorPicker,
    handleOutclickEdit,
    loader,
    drawingMode,
    threePointStart,
    closeDrawingMode,
    addCornerSupport,
    lockCanvas,
    lockOrUnlockCanvas,
    movePoints,
    movePoint,
    netItems,
    saveNetInfo,
    renderNetObj,
    showAddNet,
    deleteNet,
    cancelNetCreation,
    showCancel,
    netObj,
    handlePanelEdit,
    editPanel,
    deletePanel,
    btnLoader,
    openSupportConfirmModal,
    handleSaveCanvasData,
    onConfirmHandler,
    checkFunc,
    // magicLinkForm,
    emailVerifyForm,
    openSignupForm,
    openSignupModal,
    closeSignupModal,
    magicFormBtnLoader,
    getUserDetails,
    handleQuantityChange,
    handleResetModal,
    showResetModal,
    onConfirmPanelReset,
    handlePanelCancelModal,
    cancelPanelModal,
    polyData,
    handleCancelMagiModal,
    magicConfirmOpen,
    closeMagicConfirmModal,
    mailCheckModalOpen,
    netSupportRedirect,
    isDefaultPanelModalOpen,
    handleOpenDefaultPanelModal,
    handleCloseDefaultPanelModal,
    getDefaultPanelData,
    defaultPanels,
    addSelectDefaultPanels,
    selectedPanels,
    setSelectedPanels,
    checkForThreePointStart,
    panleLibLoginMsg,
    handlePanleLibLoginMsg,
    openDeleteNet,
    closeDeleteNetModal,
    openDeleteNetModal,
    getEditorMenuData,
    editorMenuList,
    editorNets,
    editorSupports,
    openOTPLoginForm,
    openOTPLoginModal,
    closeOTPLoginModal,
    generateLoginOTP,
    isInvalidCode,
    isValidCode,
    inputOtp,
    userEmail,
    verifyLoginOTP,
    resendOTP,
    setInputOtp,
    openPasswordLoginForm,
    openPasswordModal,
    closePasswordModal,
    loginWithPasswordForm,
    continueWithUniqueCode,
    selectedNetData

  } = ForCanvasEditor();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const hostname = window.location.hostname;


  // const navigate = useNavigate();
  // let token: any = localStorage.getItem('token') ? localStorage.getItem('token') : "";
  // const { isExpired } = useJwt(token);

  // const checkTokenExpiration = () => {
  //   console.log(localStorage.getItem('token'), '# token');
  //   if (localStorage.getItem('token') && isExpired) {
  //     console.log('if part #');
  //     navigateToGn();
  //   } else {
  //     console.log('else part #');

  //   }
  // }
  // let token = searchParams.get('token') || getQueryStringValue('link') || localStorage.getItem('token') || "";
  // const { isExpired } = useJwt(token);

  // useEffect(() => {
  //   console.log("Call to canvash 001")
  //   if (isExpired) {
  //     localStorage.clear();
  //     dispatch({ type: "RESET_STATE" });
  //     window.location.replace(navigateToGn('login?action=logout'))
  //   } else {
  //     let userId = searchParams.get('userId')
  //     if (userId) {
  //       console.log(userId, "userId 2")
  //       localStorage.setItem('userId', JSON.stringify(getQueryStringValue('userId')))
  //       if (searchParams.get('link') && process.env.REACT_APP_PUBLIC_GET_TOKEN) {
  //         console.log('token *')
  //         const token = TokenGet(getQueryStringValue('link'), process.env.REACT_APP_PUBLIC_GET_TOKEN)
  //         localStorage.setItem('token', token)
  //       } else if (searchParams.get('token')) {
  //         localStorage.setItem('token', getQueryStringValue('token'))
  //       }
  //     }
  //     getUserDetails();
  //   }

  // }, [token]);

  //Getting default panel data at initial render
  useEffect(() => {
    getEditorMenuData();
    getDefaultPanelData();
  }, [])

  useEffect(() => {
    if (editor) {
      const guideline = new AlignGuidelines({
        canvas: editorMemo,
        aligningOptions: {
          lineColor: "red",
          lineWidth: 2,
          lineMargin: 2,
        },
      });

      guideline.init();
    }
  }, [editor]);

  useEffect(() => {
    if (!netItems?.length) {
      props.getSupports();
    }
  }, [netItems]);

  const checkPlatform =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "Mobile"
      : "Desktop";

  const driverObj = driver({
    showProgress: true,
    //.driverjs-theme style is available in src/index.css
    popoverClass: "driverjs-theme",
    steps: [
      {
        element: ".drive-item-1",
        popover: { title: "Select Solar Panel", },
      },
      {
        element: ".panel-add-button",
        popover: {
          title: "Customize your Solar Panel",

        },
      },
      {
        element: ".default-panel-add-button",
        popover: {
          title: "Search available solar panels",

        },
      },
      {
        element: "#panel0",
        popover: {
          title: "Select Default Panel and click to draw structure",

        },
      },
      {
        element: "#panel1",
        popover: {
          title: "Select Default Panel and click to draw structure",
        },
      },
      {
        element: ".drive-item-2",
        popover: { title: "Select net type & Color", },
      },
      {
        element: "#solarnet0",
        popover: {
          title: "Select & Draw White Solar GolfNet",

        },
      },
      {
        element: "#solarnet1",
        popover: {
          title: "Select & Draw Black Solar GolfNet",

        },
      },
      {
        element: "#ballnet2",
        popover: {
          title: "Select & Draw White Solar Hail Blocker",

        },
      },
      {
        element: "#ballnet3",
        popover: {
          title: "Select & Draw Black Solar Hail Blocker",

        },
      },

      {
        element: ".drive-item-3",
        popover: { title: "Select Supports", },
      },
      {
        element: "#cornerSupport0",
        popover: {
          title: "Click to add Corner Supports",

        },
      },
      {
        element: "#sideSupport1",
        popover: {
          title: "Drag & Drop Side Supports",

        },
      },
      {
        element: "#valleySupport2",
        popover: {
          title: "Drag & Drop Valley Supports",

        },
      },
      {
        element: "#threePointSupport3",
        popover: {
          title: "Click to add Three Point Support",
        },
      },



      {
        element: "#zoom-in",
        popover: {
          title: "Zoom–In",

        },
      },
      {
        element: "#zoom-out",
        popover: { title: "Zoom–Out", },
      },
      {
        element: "#resetzoom",
        popover: {
          title: "Reset Zoom to default",

        },
      },
      {
        element: "#undo",
        popover: { title: "Undo", },
      },
      {
        element: "#redo",
        popover: { title: "Redo", },
      },
      {
        element: ".header-mid-img",
        popover: {
          title: "Enter personalized Quote name",
        },
      },
      {
        element: ".delete-all-nets",
        popover: {
          title: "Delete all created nets in a Quote",
        },
      },
      {
        element: ".lock-unlock",
        popover: {
          title: "Hide/Unhide net dimensions",
        },
      },
      {
        element: ".save-next-quote",
        popover: {
          title: "Click Next/Save to proceed",
        },
      },

      {
        popover: {
          popoverClass: 'video_popover',
          align: 'center',
          showProgress: false,
          title: "User Walkthrough",
          description: `
              <video autoplay width="100%" controls style="height:100%">
              <source src="https://${hostname}/1-full.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>`,
          // description: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/mgR1Mwnram8?si=djJe6ELmxgVzY-Sk?autoplay=1" title="YouTube video player" allow="autoplay" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
        },
      },
    ],
    animate: true,
    onDestroyed: (element: any, step: any, options: any) => {
      localStorage.setItem("driver", "true");
      const userId = localStorage.getItem("userId");
      if (userId) {
        let data = {
          url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`,

          bodyData: {
            tour: true,
          }
        }
        doPutApiCall(data)
          .then((response: any) => {
            console.log(response?.result?.tour, "User tour updated successfully");
          })
          .catch((error) => {
            console.error(error, "User tour update failed")
          });
      }

    },
    onNextClick: async (element: any, step: any, options: any) => {
      if (options?.state?.activeStep?.element === ".drive-item-1") {
        handlePannelType("Solar Panel");
        setTimeout(() => {
          driverObj.moveNext();
        }, 100);
      } else if (options?.state?.activeStep?.element === ".drive-item-2") {
        handlePannelType("Solar Net");
        setTimeout(() => {
          driverObj.moveNext();
        }, 100);
      } else if (options?.state?.activeStep?.element === ".drive-item-3") {
        handlePannelType("Support");
        setTimeout(() => {
          driverObj.moveNext();
        }, 100);
      } else {
        setTimeout(() => {
          driverObj.moveNext();
        }, 100);
      }

      // localStorage.setItem("driver", 'true')
    },
    onPrevClick: async (element: any, step: any, options: any) => {
      console.log("prev", options?.state?.activeStep?.element);
      if (options?.state?.activeStep?.element === ".drive-item-2") {
        handlePannelType("Solar Panel");
        setTimeout(() => {
          driverObj.movePrevious();
        }, 100);
      } else if (options?.state?.activeStep?.element === ".drive-item-3") {
        handlePannelType("Solar Net");
        setTimeout(() => {
          driverObj.movePrevious();
        }, 100);
      } else {
        setTimeout(() => {
          driverObj.movePrevious();
        }, 100);
      }
    },
  });

  const handleDriver = () => {
    if (threePointStart) {
      checkForThreePointStart(threePointStart);
      return;
    }
    driverObj.drive();
  };

  // useEffect(() => {
  //   if (localStorage.getItem("driver") !== "true") {
  //     driverObj.drive();
  //   }
  // }, []);

  useEffect(() => {
    props.getOrder()
  }, [])


  const [selectedStep, setselectedStep]: any = useState(null);


  return (
    <div className="flex flex-col overflow-hidden mt-[56px]" style={{ height: "calc(100vh - 56px)" }}>
      {checkPlatform === "Mobile" ? (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            // marginTop: "24px",
            // marginLeft: "5px",
          }}
        >
          * This website is best viewed on a larger screen for an optimal
          browsing experience.
        </p>
      ) : null}
      <Header
        handleDelete={onDeleteSelection}
        showDelete={showDelete}
        clearCanvas={clearCanvas}
        saveCanvasData={saveCanvasData}
        handleZoom={updateZoom}
        quoteData={quoteData}
        openEdit={openEdit}
        onChange={onQuoteNameChange}
        handleOpenEdit={handleOpenEdit}
        selected={selected}
        selectionType={selectionType}
        enableDisableClickThrough={enableDisableClickThrough}
        spanning={spanning}
        resetZoom={resetZoom}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        handleOutclickEdit={handleOutclickEdit}
        loader={loader}
        checkPlatform={checkPlatform}
        drawingMode={drawingMode}
        closeDrawingMode={closeDrawingMode}
        threePointStart={threePointStart}
        lockCanvas={lockCanvas}
        lockOrUnlockCanvas={lockOrUnlockCanvas}
        movePoints={movePoints}
        move={movePoint}
        saveNetInfo={saveNetInfo}
        showAddNet={showAddNet}
        cancelNetCreation={cancelNetCreation}
        showCancel={showCancel}
        openSignupForm={openSignupForm}
        // openSignupModal={openSignupModal}
        closeSignupModal={closeSignupModal}
        // magicLinkForm={magicLinkForm}
        emailVerifyForm={emailVerifyForm}
        magicFormBtnLoader={magicFormBtnLoader}
        btnLoader={btnLoader}
        openSupportConfirmModal={openSupportConfirmModal}
        handleSaveCanvasData={handleSaveCanvasData}
        onConfirmHandler={onConfirmHandler}
        checkFunc={checkFunc}
        netData={netData}
        handleResetModal={handleResetModal}
        showResetModal={showResetModal}
        onConfirmPanelReset={onConfirmPanelReset}
        handlePanelCancelModal={handlePanelCancelModal}
        cancelPanelModal={cancelPanelModal}
        handleCancelMagiModal={handleCancelMagiModal}
        magicConfirmOpen={magicConfirmOpen}
        closeMagicConfirmModal={closeMagicConfirmModal}
        mailCheckModalOpen={mailCheckModalOpen}
        netSupportRedirect={netSupportRedirect}
        handleDriver={handleDriver}
        editorMemo={editorMemo}
        openOTPLoginForm={openOTPLoginForm}
        openOTPLoginModal={openOTPLoginModal}
        closeOTPLoginModal={closeOTPLoginModal}
        generateLoginOTP={generateLoginOTP}
        isInvalidCode={isInvalidCode}
        isValidCode={isValidCode}
        inputOtp={inputOtp}
        userEmail={userEmail}
        verifyLoginOTP={verifyLoginOTP}
        resendOTP={resendOTP}
        setInputOtp={setInputOtp}
        openPasswordLoginForm={openPasswordLoginForm}
        openPasswordModal={openPasswordModal}
        closePasswordModal={closePasswordModal}
        loginWithPasswordForm={loginWithPasswordForm}
        continueWithUniqueCode={continueWithUniqueCode}
      />

      <div
        className={"w-full h-full flex justify-between overflow-hidden"}
        style={{
          marginTop:
            checkPlatform === "Mobile" ? "81px !important" : "",
        }}
      >
        <div className="mainSection-sidebar">
          <SideNav pannelType={pannelType} handleClick={handlePannelType} sideNavData={sideNavData} selectedStep={selectedStep} setselectedStep={setselectedStep} />
          {pannelType === "Solar Panel" ? (
            <SidePanel
              sidePanelRoot={``}
              pannelType={"Solar Panel"}
              onClick={onAddRectangle}
              handleClearPanelType={handleClearPanelType}
              handlePanelModal={handlePanelModal}
              openPanelModal={openPanelModal}
              handleClosePanelModal={handleClosePanelModal}
              panelForm={panelForm}
              // magicLinkForm={magicLinkForm}
              emailVerifyForm={emailVerifyForm}
              openSignupForm={openSignupForm}
              openSignupModal={openSignupModal}
              closeSignupModal={closeSignupModal}
              magicFormBtnLoader={magicFormBtnLoader}
              panels={customPanelArr}
              blockPickerColor={blockPickerColor}
              handleColorPicker={handleColorPicker}
              draggable
              handlePanelEdit={handlePanelEdit}
              editPanel={editPanel}
              deletePanel={deletePanel}
              loader={loader}
              handleCancelMagiModal={handleCancelMagiModal}
              magicConfirmOpen={magicConfirmOpen}
              closeMagicConfirmModal={closeMagicConfirmModal}
              isDefaultPanelModalOpen={isDefaultPanelModalOpen}
              handleOpenDefaultPanelModal={handleOpenDefaultPanelModal}
              handleCloseDefaultPanelModal={handleCloseDefaultPanelModal}
              defaultPanels={defaultPanels}
              addSelectDefaultPanels={addSelectDefaultPanels}
              selectedPanels={selectedPanels}
              setSelectedPanels={setSelectedPanels}
              getDefaultPanelData={getDefaultPanelData}
              panleLibLoginMsg={panleLibLoginMsg}
              handlePanleLibLoginMsg={handlePanleLibLoginMsg}
              selectedStep={selectedStep}
            />
          ) : pannelType === "Solar Net" ? (
            <SidePanel
              polyData={polyData}
              pannelType={"Solar Net"}
              onClick={clickAddNet}
              handleClearPanelType={handleClearPanelType}
              getDefaultPanelData={getDefaultPanelData}
              editorNets={editorNets}
              selectedStep={selectedStep}
              selectedNet={selectedNetData}

            />
          ) : pannelType === "Support" ? (
            <SidePanel
              threePointStart={threePointStart}
              pannelType={"Support"}
              handleClearPanelType={handleClearPanelType}
              onClick={onAddSupport}
              addCornerSupport={addCornerSupport}
              getDefaultPanelData={getDefaultPanelData} draggable
              editorSupports={editorSupports}
              selectedStep={selectedStep}
            />
          ) : null}
        </div>
        <div className="mainSection-editorSection" id={"canvas-container"}>
          <FabricJSCanvas className={"sample-canvas"} onReady={onReady} />
        </div>
        <div style={{ float: "right" }}>
          <RightSideNav
            netData={netData}
            renderNetObj={renderNetObj}
            deleteNet={deleteNet}
            openDeleteNetModal={openDeleteNetModal}
            closeDeleteNet={closeDeleteNetModal}
            openDeleteNet={openDeleteNet}
            netObj={netObj}
            handleQuantityChange={handleQuantityChange}
          />
        </div>
      </div>

    </div>
  );
};

export default Editor;

// new design on conflict need to fix
//     <SidePanel pannelType={'Solar Net'} onClick={onAddNet} handleClearPanelType={handleClearPanelType} /> : pannelType === 'Support' ?
//       <SidePanel pannelType={'Support'} handleClearPanelType={handleClearPanelType} onClick={onAddSupport} /> : null} */}

// <SidePanel
//   sidePanelRoot={`duration-500 ${pannelType !== '' ? `w-[12em]` : `w-0`}`}
//   pannelType={pannelType === 'Solar Panel' ? 'Solar Panel' : pannelType === 'Solar Net' ? 'Solar Net' : pannelType === 'Support' ? 'Support' : null}
//   onClick={pannelType === 'Solar Panel' ? onAddRectangle : pannelType === 'Solar Net' ? onAddNet : pannelType === 'Support' ? onAddSupport : null}
//   handleClearPanelType={handleClearPanelType}
//   handlePanelModal={handlePanelModal}
//   openPanelModal={openPanelModal}
//   handleClosePanelModal={handleClosePanelModal}
//   panelForm={panelForm}
//   panels={customPanelArr}
//   blockPickerColor={blockPickerColor}
//   handleColorPicker={handleColorPicker}
// />
