import { useEffect, useState } from "react";
import "./index.css";
import openClosePannelDrawer from "../../Images/Notch.svg";


const SideAssemblyPanel = (props: any) => {
  const [sidePanelArr, setSidePanelArr]: any = useState();
  // const [selectedPanel, setSelectedPanel]: any = useState(null);


  const { pannelData, selectedOption, selectedNet } = props

  // useEffect(() => {

  //   setSelectedPanel(null)

  // }, [selectedOption]);





  useEffect(() => {
    if (props?.pannelType && props.data?.length > 0) {
      setSidePanelArr(props.data);
    }
  }, [props?.pannelType, props.data])


  // const onPannelClick = (item: any, idx: number) => {
  //   !pannelData.draggable && props.onClick(item);
  //   setSelectedPanel(idx)
  // }


  return (
    <div className={`relative z-10 w-[200px] bg-[#fff] shadow-lg h-full`}>
      <div className={`flex flex-col items-center gap-2 h-full`}>
        <p className={`mt-3 text-[12px] font-medium w-[70%] text-center py-2 rounded-full bg-[#cad8ff]/[0.3] text-blue-500 flex justify-center items-center`}>
          {pannelData.draggable ? "Please Drag to add" : "Please Click to add"}
        </p>
        <div className="mb-3 flex w-full flex-col items-center h-[87vh] gap-3 overflow-y-scroll py-3">
          {
            (sidePanelArr?.map((item: any, idx: number) => {
              return (
                <div
                  id={item?.type + idx}
                  className={`
                    w-[80%] rounded-xl p-2
                    ${item?.quantity > 0 ? null : "opacity-60"}
                    ${selectedNet === item._id ? "border-[#2C71F6] border-[2px]" : null}
                  `}
                  key={idx}
                  onClick={() => !pannelData.draggable && props.onClick(item)}
                  // onDrag={() => pannelData.draggable && onPannelClick(item, idx)}
                >
                  {item?.diagram?.key || item?.key ? (
                    <div className={`relative flex cursor-pointer flex-col items-center text-center panel-border`}>
                      <img
                        crossOrigin="anonymous"
                        src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.diagram?.key ? item?.diagram?.key : item?.key}`}
                        alt="img"
                        width={1800}
                        height={1800}
                        className={`w-[200px] h-[100px] object-contain`}
                        draggable={pannelData.draggable}
                        id={JSON.stringify(item)}

                      />
                      <p className={`text-[16px] font-medium capitalize flex flex-wrap mt-1`}>{item?.title}</p>
                      <div className="absolute top-1 right-1 w-6 h-6 rounded-full bg-[#3B82F6] flex justify-center items-center text-white text-[12px]">{item?.quantity}</div>
                    </div>
                  ) : (
                    <div className="group relative flex cursor-pointer flex-col items-center justify-center">
                      <p
                        className={`${item?.height > item?.width
                          ? `h-[5rem] w-[3rem]`
                          : `h-[3rem] w-[5rem]`
                          }`}
                        style={{
                          background: `${item?.fill ? item?.fill : "#013A7D"}`,
                        }}
                        draggable={pannelData.draggable}
                        id={JSON.stringify(item)}
                        onClick={() => props.onClick(item)}
                      ></p>
                    </div>
                  )}

                </div>
              );
            }))
          }
        </div>
      </div>
      <div
        className="absolute -right-5 top-[50%] z-10 flex h-12 w-6 cursor-pointer items-center justify-end rounded-l-md font-[SansRegular] !font-semibold duration-500 "
        onClick={props.handleClearPanelType}
      >
        <p className="flex items-center gap-4">
          <span>
            <img src={openClosePannelDrawer} alt="openClosePannelDrawer" className="ml-[2.5px]" />
          </span>
        </p>
      </div>
    </div>
  );
};

export default SideAssemblyPanel;
