import { useCallback, useEffect, useMemo, useState } from "react";
import { fabric } from "fabric";
import { useFabricJSEditor } from 'fabricjs-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { doGetApiCall, doPostApiCall } from "../../utils/ApiConfig";
import { getQuoteData } from "../quote/quoteReducer/diagramReducer";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationOpen } from "../notificationReducer/NotificationReducer";
import 'fabric-history';
import { uploadFileData } from "../../redux/action";
import { navigateToGn } from "../../utils/navigateToGn";
import { v4 as uuidv4 } from 'uuid';
import { sideNavAssemblyData } from "../../common/commonSideNav/sideNavData";
import { getManufacturerTag } from "../../utils/fabricSupport";


export const ForAssemblyEditor = () => {
    const { editor, onReady } = useFabricJSEditor()
    const [pannelType, setPannelType] = useState('Add Ropes');
    const editorMemo: any = useMemo(() => editor?.canvas, [editor?.canvas]);
    const [netInfo, setNetInfo]: any = useState([])
    const [showDelete, setShowDelete] = useState(false)
    const [pxValue] = useState(0.8)   //1px = .8 inch  //1 inch = 1.26px //.5 inch = .625px
    const [panningMode, setPanningMode] = useState(false)
    const [loadJson, setLoadJson] = useState(true)
    const [quoteData, setQuoteData]: any = useState({});
    const [selectionType, setSelectionType] = useState('');
    const [selected, setSelected] = useState(false);
    const [loader, setLoader] = useState(false);
    const ZOOM_PERCENT = 1.2;
    const [addLine, setAddLine] = useState(false);
    const [linePoints, setLinePoints]: any = useState([]);
    const [point, setPoint]: any = useState({});
    const [openSupportConfirmModal, setOpenSupportConfirmModal] = useState(false)
    const [showResetModal, setShowResetModal]: any = useState(false);
    const [selectedData, setSelectedData]: any = useState({});
    const [itemArr, setItemArr]: any = useState([]);
    const [staticLength] = useState(12) // static extra length 12 inches needs to be added on each rope kepping it in inches no ft because it can be changed and value will be in inches
    const [lockCanvas, setLockCanvas] = useState(false);
    const [addRope, setAddRope] = useState(false);
    const [selectedPanel, setSelectedPanel]: any = useState(sideNavAssemblyData[0]);
    const [addSupport, setAddSupport] = useState(false);
    const [adminSupports, setAdminSupports]: any = useState([]);
    const [ropeSupport, setRopeSupport]: any = useState({});
    const [wireSupport, setWireSupport]: any = useState({});
    const [loading, setLoading] = useState(false);
    const [enableRopeSupport, setEnableRopeSupport] = useState(false);
    const [showSoftAlert, setShowSoftAlert] = useState(false);
    const [showSave, setShowSave] = useState(true);
    const [zipSupport, setZipSupport]: any = useState({});
    const [version, setVersion] = useState('');
    const [selectedNet, setSelectedNet] = useState('');
    const [showDeleteLine, setShowDeleteLine] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);
    const [saveType, setSaveType]: any = useState('');

    const dispatch = useDispatch()
    const navigate = useNavigate();


    const customValueArr = ['name', 'id', 'objectType', 'netId', 'netColour', 'thickness', 'area', 'sides', 'panelId', 'addedBy', 'supportId', 'length', 'moduleName']

    const propertiesToInclude = ['hasControls', 'hasRotatingPoint', 'lockUniScaling', 'lockRotation', 'lockMovementX', 'lockMovementY']


    const netItems = useSelector((state: any) => state.diagramReducer?.getAllSupportList);
    const ropeSId = process.env.REACT_APP_PUBLIC_ropeSupportId ?? '';
    const wireSId = process.env.REACT_APP_PUBLIC_wireSupportId ?? '';
    const zipSId = process.env.REACT_APP_PUBLIC_zipSupportId ?? '';

    const [searchParams] = useSearchParams();

    const handleAddtype = (data: any) => {
        setSelectedData({});
        setSelectedNet('');
        if (editorMemo.getObjects().length > 0 && data.title != pannelType) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please complete current design`, alertType: "error", borderClass: "error" }));
        }
        else {
            setPannelType(data.title);
            setSelectedPanel(data);
            if (data.type === 'rope_support') {
                setShowSave(true);
            }
            else {
                setShowSave(false);
            }
        }
    }

    const handleClearPanelType = () => {
        setPannelType('');
        setSelectedPanel({});

    }


    const addDataToLocalStorage = () => {
        let token = searchParams.get('token') || '';
        localStorage.setItem('token', token);
        let quoteId = searchParams.get('quoteId') || '';
        localStorage.setItem('quoteId', quoteId);
        let userId = searchParams.get('userId') || '';
        localStorage.setItem('userId', userId);
        let orderId = searchParams.get('orderId') || '';
        localStorage.setItem('orderId', orderId);
        let role = searchParams.get('role') || '';
        localStorage.setItem('role', role);
    }

    const addBackGround = () => {
        // Define grid properties
        let gridSize = 15; // Size of each grid cell around 12 inch in our calculation
        let numGridX = editorMemo.width / gridSize; // Number of grid cells in X direction
        let numGridY = editorMemo.height / gridSize; // Number of grid cells in Y direction

        // Create grid lines
        for (let i = 0; i < numGridX; i++) {
            let lineX = new fabric.Line([i * gridSize, 0, i * gridSize, editorMemo.height], {
                stroke: 'lightgray',
                selectable: false
            });
            editorMemo.add(lineX);
            editorMemo.sendToBack(lineX);
        }

        for (let j = 0; j < numGridY; j++) {
            let lineY = new fabric.Line([0, j * gridSize, editorMemo.width, j * gridSize], {
                stroke: 'lightgray',
                selectable: false
            });
            editorMemo.add(lineY);
            editorMemo.sendToBack(lineY);
        }

        // Set background image with grid lines as a pattern
        let backgroundPattern = editorMemo.toDataURL(); // Convert canvas to image URL
        editorMemo.setBackgroundImage(backgroundPattern, function () {
            // After setting the background image, remove the grid lines
            let objects = editorMemo.getObjects();
            objects.forEach(function (object: any) {
                if (object.type === "line") {
                    editorMemo.remove(object);
                }
            });
            editorMemo.renderAll();
        });
    }
    useEffect(() => {
        if (editor) {
            editorMemo.backgroundColor = "white";
            editorMemo.renderingBackend = 'webgl';
            editorMemo.renderOnAddRemove = false;
            fabric.IText.prototype.borderColor = 'blue';
            if (editorMemo.backgroundImage) {
                // There is a background image set
                console.log("Canvas has a background image.");
            }
            else {
                addBackGround()
            }


            document.addEventListener("dragstart", function (event: any) {
                if (event.stopPropagation) {
                    event.stopPropagation(); //Stops some browsers from redirecting.
                }
                event.dataTransfer.setData("dragId", event.target.id);

            });
            document.addEventListener("drop", handleDrop);

            let quoteId = searchParams.get('quoteId') || '';
            if (quoteId?.length > 0 && loadJson) {
                getQuotes(quoteId);
                getSupports();
                setLoadJson(false);
                addDataToLocalStorage();
            }

            const originalToObject = fabric.Object.prototype.toObject;
            fabric.Object.prototype.toObject = function (additionalProperties: any) {
                return originalToObject.call(this, customValueArr.concat(additionalProperties));
            }


            document.addEventListener("dblclick", removePanning);


            document.addEventListener("keydown", listenKeyBoard);

            const activeObj = editorMemo.getActiveObject();
            if (activeObj && Object.keys(activeObj)?.length > 0 && activeObj.addedBy === 'admin') {
                setShowDelete(true)
            }

            editorMemo.on('object:moving', (options: any) => {
                if (options.target.type === 'group') {
                    let snap = 20; //Pixels to snap
                    options.target.setCoords();
                    var bound = options.target.getBoundingRect();
                    // Don't allow objects off the canvas
                    if (bound.left < snap) {
                        options.target.set({ left: 0 });
                    }

                    if (bound.top < snap) {
                        options.target.set({ top: 0 });
                    }

                    if ((options.target.width + bound.left) > (editorMemo.width - snap)) {
                        options.target.set({ left: (editorMemo.width - options.target.width) + 0.625 });
                    }

                    if ((options.target.height + bound.top) > (editorMemo.height - snap)) {
                        options.target.set({ top: (editorMemo.height - options.target.height) + 0.625 });
                    }
                }

            });

            return () => {
                document.removeEventListener("drop", handleDrop);
                document.removeEventListener("dragstart", function (event: any) {
                    if (event.stopPropagation) {
                        event.stopPropagation(); //Stops some browsers from redirecting.
                    }
                    event.dataTransfer.setData("dragId", event.target.id);

                });
                document.removeEventListener("dblclick", removePanning);
                document.removeEventListener("keydown", listenKeyBoard);
            };

        }
    }, [editor]);

    const spanning = () => {
        setPanningMode(!panningMode)
        toggleDragMode(!panningMode)
        if (!panningMode) {
            editorMemo.defaultCursor = 'grabbing'
        }
        else {
            editorMemo.defaultCursor = 'default'
        }
    }


    const removePanning = () => {
        if (panningMode) {
            setPanningMode(false)
            toggleDragMode(false)
            editorMemo.defaultCursor = 'default'
        }
    }


    const onDeleteSelection = async () => {
        const activeSelection = editorMemo.getActiveObject();
        if (activeSelection && activeSelection._objects) {
            activeSelection._restoreObjectsState();
            activeSelection._objects.map((object: any) => editorMemo.remove(object));
        }
        if (activeSelection && activeSelection.objectType === 'support') {
            if (activeSelection.supportId === ropeSId) {
                let lineObj = editorMemo.getObjects()?.find((data: any) => data.id === `support_line-${activeSelection.id}`);
                if (lineObj) {
                    editorMemo.remove(lineObj);
                }
            }

        }
        if (activeSelection && activeSelection.objectType === 'net') {
            setAddSupport(false);
            editorMemo.clear();
        }
        if (activeSelection && activeSelection.type === 'group') {
            let arr = [...netInfo]
            let index = arr.findIndex((data: any) => data.id === activeSelection.id)
            if (index > -1) {
                arr[index].quantity = arr[index].quantity + 1
            }
            setNetInfo(arr);
            setAdminSupports([]);
            let wireSupports = editorMemo.getObjects()?.filter((data: any) => data.objectType === 'support' && data.supportId === wireSId);
            if (wireSupports && wireSupports.length > 0) {
                await Promise.all(
                    wireSupports.map(async (data: any) => {
                        await editorMemo.remove(data);
                    })
                )
            }

        }
        editorMemo.remove(activeSelection);
        editorMemo.renderAll();
        setShowDelete(false);
    };

    const handleResetModal = () => {
        setShowResetModal(!showResetModal)
    }
    const clearCanvas = () => {
        if (editor) {
            resetData();
            editorMemo.clear();
            editorMemo.renderAll();
            setShowResetModal(false);
            let arr = netInfo.map((data: any) => {
                data.quantity = data.totalQty
                return data
            })
            setNetInfo(arr)
        }
    }
    const handleSaveCanvasData = () => {
        setOpenSupportConfirmModal(false)
    }

    const updateZoom = useCallback(
        (event: any, type: string) => {
            let delta;
            if (type === 'zoomin') {
                delta = editorMemo.getZoom() * ZOOM_PERCENT
            }
            else {
                delta = editorMemo.getZoom() / ZOOM_PERCENT
            }
            editorMemo.setZoom(delta);
            editorMemo.setViewportTransform(editorMemo.viewportTransform);
            let img = editorMemo.backgroundImage;
            if (img) {
                img.scaleX = editorMemo.width / (img.width * delta);
                img.scaleY = editorMemo.height / (img.height * delta);
            }
            event.preventDefault();
            event.stopPropagation();
            editorMemo.renderAll();

        },
        [editor]
    );


    // For Canvas panning functionality
    const STATE_IDLE = 'idle';
    const STATE_PANNING = 'panning';
    const toggleDragMode = (dragMode: any) => {
        // Remember the previous X and Y coordinates for delta calculations
        let lastClientX: any;
        let lastClientY: any;
        // Keep track of the state
        let state = STATE_IDLE;
        // We're entering dragmode
        if (dragMode) {
            // Discard any active object
            editorMemo.discardActiveObject();
            // Set the cursor to 'move'
            editorMemo.defaultCursor = 'move';
            // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
            editorMemo.forEachObject(function (object: any) {
                object.prevEvented = object.evented;
                object.prevSelectable = object.selectable;
                object.evented = false;
                object.selectable = false;
            });
            // Remove selection ability on the canvas
            editorMemo.selection = false;
            // When MouseUp fires, we set the state to idle
            editorMemo.on('mouse:up', function () {
                state = STATE_IDLE;
            });
            // When MouseDown fires, we set the state to panning
            editorMemo.on('mouse:down', (e: any) => {
                state = STATE_PANNING;
                lastClientX = e.e.clientX;
                lastClientY = e.e.clientY;
            });
            // When the mouse moves, and we're panning (mouse down), we continue
            editorMemo.on('mouse:move', (e: any) => {
                if (state === STATE_PANNING && e && e.e) {
                    // Calculate deltas
                    let deltaX = 0;
                    let deltaY = 0;
                    if (lastClientX) {
                        deltaX = e.e.clientX - lastClientX;
                    }
                    if (lastClientY) {
                        deltaY = e.e.clientY - lastClientY;
                    }
                    // Update the last X and Y values
                    lastClientX = e.e.clientX;
                    lastClientY = e.e.clientY;

                    let delta = new fabric.Point(deltaX, deltaY);
                    editorMemo.relativePan(delta);
                }
            });
        } else {
            // When we exit dragmode, we restore the previous values on all objects
            editorMemo.forEachObject(function (object: any) {
                object.evented = (object.prevEvented !== undefined) ? object.prevEvented : object.evented;
                object.selectable = (object.prevSelectable !== undefined) ? object.prevSelectable : object.selectable;
            });
            // Reset the cursor
            editorMemo.defaultCursor = 'default';
            // Remove the event listeners
            editorMemo.off('mouse:up');
            editorMemo.off('mouse:down');
            editorMemo.off('mouse:move');
            // Restore selection ability on the canvas
            editorMemo.selection = true;
        }
    };

    // Funtion to format number if length>8 then 8.8 will result 8, from 8.9 onwards will result 9 else: 8.2 will result 8, from 8.3 onwards till 8.9 will result 9
    const formatNumber = (input: any) => {
        const number = parseFloat(input);
        const numberInFeet = (number * pxValue) / 12
        if (!isNaN(number)) {
            const threshold = numberInFeet < 8 ? 0.2 : 0.8; // we can adjust this threshold as needed
            const roundedNumber = Math.round(number);

            if (number - roundedNumber > threshold) {
                return (roundedNumber + 1);
            } else {
                return roundedNumber;
            }
        } else {
            return input; // Return input if no valid output foundsetItemArr
        }
    }


    const getQuotes = async (quoteId: string) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes/${quoteId}`,
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(getQuoteData(res.result));
                    setQuoteData(res.result);
                    setItemArr(res.result?.items);
                    editorMemo.clear();
                    let netArr: any = [];
                    if (res.result?.canvasData?.length > 0) {
                        if (res.result?.items.length > 0 && res.result?.items[0]?.json) {
                            netArr = res.result?.items.map((data: any) => {
                                let obj = { ...data }
                                const json = JSON.parse(data.json)
                                let netdata = json?.objects?.find((data: any) => { return data.objectType === 'net' })
                                if (netdata) {
                                    obj.id = netdata.id // the polygon net id to track
                                }
                                if (data?.net && data?.net?.price && data?.net?.units) {
                                    obj.unitPrice = Number(data?.net?.price);
                                    obj.units = data?.net?.units;
                                }
                                obj.title = obj.itemName
                                obj.type = 'net'
                                obj.itemId = data._id  // the id of the item saved in database
                                obj.totalQty = data.quantity // To track original quantity & use when reset
                                return obj;
                            })
                        }
                        else {
                            const allObj = JSON.parse(res.result?.canvasData).objects;
                            const arr: any = allObj?.filter((data: any) => { return data?.objectType === 'net' })

                            netArr = arr.map((data: any) => {
                                let nameObj = allObj?.find((obj: any) => { return obj.id === `name-${data.id}` });
                                let obj: any = {
                                    id: data.id,
                                    itemName: nameObj.text,
                                    height: formatNumber((((data.height * data.scaleY) * pxValue) / 12)),
                                    width: formatNumber((((data.width * data.scaleX) * pxValue) / 12)),
                                }
                                obj.area = formatNumber(obj.height * obj.width);
                                obj.title = obj.itemName
                                obj.type = 'net'
                                obj.itemId = data._id
                                obj.totalQty = data.quantity
                                obj.totalQty = data.quantity // To track original quantity & use when reset
                                return obj;
                            })

                        }
                        setNetInfo(netArr);
                    }

                }
            })
            .catch((err: any) => {
                console.log(err, "<<-- err")
            })
    }





    const enableDisableClickThrough = () => {
        setSelected(!selected);
        const activeObj = editorMemo.getActiveObject()
        activeObj.set({ evented: selected })
        editorMemo.renderAll();
        if (selected) {
            setSelectionType('');
        }
    }

    //To add support or panel by dragging
    const handleDrop = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        let allObj = editorMemo.getObjects();
        let dragId = event.dataTransfer.getData("dragId");
        let data = JSON.parse(dragId);
        const existing = allObj?.filter((obj: any) => { return obj.id === data.id });
        if (data?.type === 'net') {
            setSelectedNet(data._id);
            if (data.totalQty > existing.length) {
                const jsonData = JSON.parse(data.json);

                const groupObj: any = {
                    left: event.layerX,
                    top: event.layerY,
                    hasControls: false,
                    hasRotatingPoint: false,
                    subTargetCheck: true,
                    perPixelTargetFind: true,
                    // originX: 'center',
                    // originY: 'center',
                    addedBy: 'admin',
                    itemId: data.itemId,
                    id: data.id
                }
                // Filter out text objects
                let objArr: any = []
                jsonData.objects.map((object: any) => {
                    if (object.type === 'rect' || object.type === 'circle') {
                        if (object.objectType === 'support') {
                            object.targetFindTolerance = 20; // Set target find tolerance to 10 pixels
                        }
                        if (object.type === 'rect') {
                            const rect = new fabric.Rect(object);
                            objArr.push(rect)
                        }
                        if (object.type === 'circle') {
                            const circle = new fabric.Circle(object);
                            objArr.push(circle)
                        }
                    }
                    if (object.type === 'polygon') {
                        const poly = new fabric.Polygon(object.points, object);
                        poly.set({ name: data.title })
                        objArr.push(poly)
                    }
                })
                // Create group with non-text objects
                const group = new fabric.Group(objArr, groupObj);
                // updatePolygonObj(group);
                // group.setCoords();
                editorMemo.add(group);
                editorMemo.renderAll();
                setAddSupport(true);
                let arr = [...netInfo]
                let index = arr.findIndex((obj: any) => { return obj.id === data.id });
                if (index > -1) {
                    arr[index].quantity = arr[index].quantity - 1
                }
                setNetInfo(arr);
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Selected net quantity exceeded`, alertType: "error", borderClass: "error" }));
            }
        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Drag Drop not allowed`, alertType: "error", borderClass: "error" }));
        }

    }

    const resetZoom = () => {
        let delta = 1
        editorMemo.setZoom(delta);
        let img = editorMemo.backgroundImage;
        if (img) {
            img.scaleX = editorMemo.width / (img.width * delta);
            img.scaleY = editorMemo.height / (img.height * delta);
        }
        editorMemo.renderAll()
    }

    const handleUndo = () => {
        editorMemo.undo(function () {
            removeControl();
        });
    }

    const handleRedo = () => {
        editorMemo.redo(function () {
            removeControl();
        });
    }




    const removeControl = () => {
        editorMemo.forEachObject(function (targ: any) {
            if (targ.objectType === 'netValue' || targ.objectType === 'netname' || targ.type === 'polygon') {
                if (targ.type === 'polygon') {
                    targ.set({ lockRotation: true, hasRotatingPoint: false, lockUniScaling: true, lockMovementX: true, lockMovementY: true, lockScalingFlip: true, hasControls: false })
                }
                else {
                    targ.set({ selectable: false })
                }
            }
            else {
                targ.set({ lockRotation: true, hasRotatingPoint: false, hasControls: false })
            }


        })
        editorMemo.renderAll();
    }

    const listenKeyBoard = (ev: any) => {
        ev.stopPropagation();
        const activeObject = editorMemo.getActiveObject();
        if (activeObject && activeObject?.type === 'group') {
            ev.preventDefault();
            let setObj: any = {};
            switch (ev.keyCode) {
                case 37:  //ArrowLeft
                    setObj.left = activeObject?.left - 2;
                    break;
                case 38: //ArrowUp
                    setObj.top = activeObject?.top - 2;
                    break;
                case 39: //ArrowRight
                    setObj.left = activeObject?.left + 2;
                    break;
                case 40: //ArrowDown
                    setObj.top = activeObject?.top + 2;
                    break;
                default:
                    console.log(`Default Case Key: ${ev.keyCode}`);
                    break;
            }
            if (Object.keys(setObj)?.length > 0) {
                activeObject.set(setObj);
                editorMemo.renderAll();
            }
        }

    }
    const onAddNet = (item: any) => {
        if (item.type === 'net' && editorMemo?.getObjects('polygon')?.length === 0) {
            setSelectedData(item);
            setSelectedNet(item?._id);
            let canvasBackground = editorMemo.backgroundImage;
            if (item?.currentJson) {
                editorMemo.loadFromJSON(item?.currentJson, function () {
                    editorMemo.getObjects().forEach((obj: any) => {
                        if (obj.objectType === 'support_line') {
                            obj.set({ selectable: false, evented: false })
                        }
                    });
                    editorMemo.requestRenderAll();
                });
            }
            else {
                let parsedData = JSON.parse(item.json);
                parsedData.objects.forEach((obj: any) => {
                    obj.selectable = false;
                    if (obj.objectType === 'netValue' || obj.objectType === 'netname') {
                        obj.visible = false
                    }
                });
                let polyIndex = parsedData.objects.findIndex((obj: any) => { return obj.type === 'polygon' });
                if (polyIndex > -1 && !parsedData.objects[polyIndex]?.moduleName) {
                    let panels = parsedData.objects.filter((obj: any) => { return obj.objectType === 'panel' && obj.name?.length > 0 });
                    if (panels?.length > 0) {
                        parsedData.objects[polyIndex].moduleName = panels[0].name
                    }
                }
                let filteredJSONData = JSON.stringify(parsedData);
                editorMemo.loadFromJSON(filteredJSONData, function () {
                    editorMemo.setBackgroundImage(canvasBackground, editorMemo.requestRenderAll.bind(editorMemo));
                });
            }
            setAddSupport(true);
        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please complete or clear current design first!`, alertType: "error", borderClass: "error" }));
        }

    }

    useEffect(() => {
        if (editor) {
            editorMemo.on('mouse:down', (options: any) => {
                editorMemo.selection = true
                options.e.stopPropagation();
                options.e.stopImmediatePropagation();
                options.e.preventDefault();
                let target: any = options.subTargets[0] ? options.subTargets[0] : options.target;
                if (target) {
                    if (addRope && target?.type !== 'line' && target?.type !== 'triangle' && (target?.objectType === 'net' || target?.objectType === 'support')) {
                        let boundingRect = target.getBoundingRect();
                        let pointer = options.pointer;
                        const width = 15;
                        let supportObj = {
                            height: (Math.sqrt(3) / 2) * width,
                            width: width,
                            hasControls: false,
                            lockRotation: true,
                            hasRotatingPoint: false,
                            objectType: 'support',
                            name: "Rope",
                            fill: '#FBA905',
                            supportId: ropeSId,
                            originX: 'center',
                            originY: 'center',
                            left: pointer.x,
                            top: target?.objectType === 'net' ? boundingRect.top : target.top,
                            addedBy: 'admin',
                            targetFindTolerance: 20,
                            netId: target.netId,
                            selectable: true,
                            evented: true,
                            angle: 180,
                            id: uuidv4()
                        }
                        const triangle: any = new fabric.Triangle(supportObj);
                        editorMemo.add(triangle);
                        setEnableRopeSupport(true);
                        editorMemo.renderAll();
                    }
                    else if (target && target.objectType === 'support') {
                        if (addLine) {
                            // Deselect all objects
                            editorMemo.discardActiveObject();
                            // Select only the clicked rectangle
                            editorMemo.setActiveObject(target);
                            setPoint(options.pointer);
                        }
                        else if (target?.type !== 'line' && target?.addedBy === 'admin') {
                            // Deselect all objects
                            editorMemo.discardActiveObject();
                            // Select only the clicked rectangle
                            editorMemo.setActiveObject(target);
                        }
                        else {
                            console.log("# This is not selectable");
                        }

                        // Render canvas
                        editorMemo.requestRenderAll();
                    }
                }
            });
            return () => {
                editorMemo.off('mouse:down');
            }
        }
    }, [addLine, editor, addRope])

    const reRenderCanvas = async () => {
        await resetData();
        editorMemo.clear();
        editorMemo.renderAll();
    }

    const resetData = () => {
        setLinePoints([]);
        setSelectedData({});
        setLockCanvas(false);
        setAddLine(false);
        setAddRope(false);
        setAddSupport(false);
        setLoader(false);
        setPoint(null);
        setShowDelete(false);
        setSelectedNet('');
        setShowDeleteLine(false);
        setDeleteMode(false);
        setAdminSupports([]);
    }

    useEffect(() => {
        console.log("#point", linePoints)
    }, [linePoints])

    // Calculate distance between two points
    function pointDistance(pointA: any, pointB: any) {   // Need this in inch will convert it in ft on createitemarr function
        const dx = ((pointB.x - pointA.x) * pxValue);
        const dy = ((pointB.y - pointA.y) * pxValue);
        return Math.sqrt(dx * dx + dy * dy);
    }

    useEffect(() => {
        if (point && point.x && addLine) {
            let arr = [...linePoints]
            arr.push(point);
            setLinePoints(arr)
            if (arr?.length > 1) {
                drawLines(arr)
            }
            setPoint(null)
        }
        else {
            setPoint(null)
        }

    }, [point])
    const drawLines = async (linePoints: any) => {
        let lines = [];
        let length: number = 0;
        let id = uuidv4()
        for (let i = 0; i < linePoints.length - 1; i++) {
            let startPoint = linePoints[i];
            let endPoint = linePoints[i + 1];
            length += pointDistance(startPoint, endPoint);
        }
        let startPoint: any = linePoints[0];
        let endPoint: any = linePoints[1];
        let obj = {
            supportId: wireSId,
            originX: 'center',
            originY: 'center',
            name: wireSupport?.name,
            addedBy: 'admin',
            fill: '#D7DC0F',
            stroke: '#D7DC0F',
            strokeWidth: 3,
            strokeDashArray: [5, 5], // Set dash pattern
            selectable: false,
            objectType: 'support',
            length: length,   //Length in inches
            evented: false,
            id: id
        }
        let line = new fabric.Line([startPoint.x, startPoint.y, endPoint.x, endPoint.y], obj);
        lines.push(line);

        // Add lines to canvas
        lines.forEach(function (line) {
            editorMemo.add(line);
        });
        const unitDirection = {
            x: Math.abs(linePoints[1].x - linePoints[0].x) / length,
            y: Math.abs(linePoints[1].y - linePoints[0].y) / length
        };
        const offset = 20;
        let textPosition = {
            left: linePoints[1].x > linePoints[0].x ? linePoints[0].x + unitDirection.x * offset : linePoints[0].x - unitDirection.x * (offset * 4),
            top: linePoints[1].y > linePoints[0].y ? linePoints[0].y + unitDirection.y * offset : linePoints[0].y - unitDirection.y
        }

        let angle = linePoints[1].x > linePoints[0].x ? Math.atan2(linePoints[1].y - linePoints[0].y, linePoints[1].x - linePoints[0].x) : Math.atan2(linePoints[0].y - linePoints[1].y, linePoints[0].x - linePoints[1].x);
        let textObj = {
            left: textPosition.left,
            top: textPosition.top,
            fontSize: 16,
            fontFamily: 'helvetica',
            fontWeight: 'bold',
            angle: angle * (180 / Math.PI),
            fill: '#FF5733',
            selectable: false,
            objectType: 'temp',
            visible: false,
            id: `wire-${id}`

        }

        let text = new fabric.Text(`${(length / 12).toFixed(2)} ft.`, textObj);
        editorMemo.add(text);
        editorMemo.discardActiveObject();
        editorMemo.requestRenderAll()
        // Calculate length of the dashed line

        setLinePoints([]);
        createOrUpdateAdminSupportArr(length, 'add')
        console.log('#Length of dashed line:', length.toFixed(2));
    }

    const createOrUpdateAdminSupportArr = (length?: number, type?: string) => {
        let supportObj: any = {};
        let supportArr = [...adminSupports];
        let supportIndex = supportArr.findIndex((data: any) => (data.id._id ? data.id._id : data.id) === wireSId);
        if (supportIndex > -1) {
            supportObj = supportArr[supportIndex]
            supportObj.quantity = type === 'add' ? Number(supportObj.quantity) + 1 : Number(supportObj.quantity) > 1 ? Number(supportObj.quantity) - 1 : 0;
            if (supportObj.quantity > 0) {
                if (length) {
                    let extraLength = ((Number(length) + Number(staticLength)) / 12).toFixed(2);
                    supportObj.length = type === 'add' ? Number(supportObj.length) + Number(extraLength) : (Number(supportObj.length) - Number(extraLength))
                }
                supportArr[supportIndex] = supportObj
            }
            else {
                supportArr = [];
            }

        }
        else {
            if (type === 'add') {
                supportObj = {
                    id: wireSId,
                    price: Number(wireSupport?.price),
                    units: 'ft.',
                    taxable: wireSupport?.taxable,
                    quantity: 1,
                    length: 0
                }
                if (length) {
                    supportObj.length = +((Number(length) + Number(staticLength)) / 12).toFixed(2);
                }
                supportArr.push(supportObj)
            }
        }
        if (supportObj?.quantity > 0) {
            let zipIndex = supportArr.findIndex((data: any) => (data.id._id ? data.id._id : data.id) === zipSId);
            let zipObj: any = {
                id: zipSId,
                price: Number(zipSupport?.price),
                units: zipSupport.units,
                taxable: zipSupport?.taxable,
                quantity: supportObj.quantity ? (supportObj.quantity * 2) : 2
            }
            if (zipIndex > 0) {
                if (supportObj?.quantity > 0) {
                    supportArr[zipIndex] = zipObj;
                }
                else {
                    supportArr.splice(zipIndex, 1);
                }
            }
            else {
                supportArr.push(zipObj)
            }
        }
        setAdminSupports(supportArr);

    }

    const getSectionData = () => {
        const objects = editorMemo.getObjects();
        if (objects.length > 0) {
            let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
            objects.forEach((obj: any) => {
                const bounds = obj.getBoundingRect(true);
                if (bounds.left < minX) minX = bounds.left;
                if (bounds.top < minY) minY = bounds.top;
                if (bounds.left + bounds.width > maxX) maxX = bounds.left + bounds.width;
                if (bounds.top + bounds.height > maxY) maxY = bounds.top + bounds.height;
            });
            minX = Math.max(0, minX);
            minY = Math.max(0, minY);
            maxX = Math.min(editorMemo.getWidth(), maxX);
            maxY = Math.min(editorMemo.getHeight(), maxY);

            let data = {
                left: minX - 50,
                top: minY - 50,
                width: (maxX - minX) + 105,
                height: (maxY - minY) + 105
            }
            return data;
        }
    }

    const getCanvasImageData = async (name?: string) => {
        if (editor) {
            resetZoom();
            let sectionData = await getSectionData();
            const options = {
                format: 'png',
                multiplier: 2,
                enableRetinaScaling: true,
                withoutShadow: true
            }
            if (!lockCanvas) {
                await lockOrUnlockCanvas();
            }
            let fileName = name ? name : quoteData.quoteName
            // Convert the sectionCanvas to an image URL
            const sectionImageURL = editorMemo.toDataURL({ ...options, ...sectionData });
            const res: Response = await fetch(sectionImageURL);
            const blob: Blob = await res.blob();
            return new File([blob], fileName, { type: 'image/png' });
        }
        else return null

    }

    const createOrderData = async (body: any) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders`,
            bodyData: body
        }
        doPostApiCall(data).then(async (res: any) => {
            if (!res.error) {
                setVersion("");
                setSaveType('');
                await localStorage.clear();
                window.location.replace(navigateToGn(`admin/order/orderdetails/${searchParams.get('orderId')}?index=1`));

            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while updating data`, alertType: "error", borderClass: "error" }));
            }
            setLoader(false)
            setLoading(false);
        })
            .catch((err: any) => {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while updating data`, alertType: "error", borderClass: "error" }));
                setLoader(false)
                setLoading(false);
                console.error(err)
            })
    }
    const saveNet = async (body: any, type?: string) => {
        const jsonObj = editorMemo.toJSON(propertiesToInclude);
        const cavasOutput = JSON.stringify(jsonObj, null, "\t");
        let arr = [...itemArr];
        let netIndex = arr.findIndex((data: any) => data._id === selectedData._id)
        if (netIndex > -1) {
            let netObj = { ...arr[netIndex] }
            let supportArr = [...netObj.support];
            let supportObj = {
                id: ropeSId,
                quantity: editorMemo.getObjects()?.filter((data: { objectType: string, supportId: string }) => { return data.objectType === 'support' && data.supportId === ropeSId }).length,
                price: Number(ropeSupport?.price),
                units: ropeSupport?.units,
                taxable: ropeSupport.taxable
            }
            supportArr.push(supportObj)
            netObj.support = supportArr
            netObj.isRopeAdded = supportObj.quantity > 0 ? true : false
            let image = await getCanvasImageData(`${netObj.itemName}.png`);
            let infoArr = [...netInfo]
            let idx = infoArr.findIndex((data: any) => data.id === selectedData.id)
            if (idx > -1) {
                let obj: any = { ...infoArr[idx] }
                obj.currentJson = cavasOutput;
                infoArr[idx] = obj;
                setNetInfo(infoArr);
            }
            await uploadFileData(image).then(async (res: any) => {
                if (res.result[0].key) {
                    netObj.adminDiagram = {
                        key: res.result[0].key,
                        name: res.result[0].originalname,
                        mimetype: res.result[0].mimetype,
                        location: res.result[0].location,
                        size: res.result[0].size,
                    }
                    arr[netIndex] = netObj;
                    setItemArr(arr);
                    body.items = arr;

                }
                await saveVersionApiCall(body, type);


            }).catch((err: any) => {
                console.log(err);
            })
        }
        reRenderCanvas()
    }

    const saveVersionApiCall = async (body: any, type?: string, sendEmail?: boolean) => {
        let versionId = version?.length > 0 ? version : localStorage.getItem("versionId") || '';
        setLoader(false);

        if (versionId && versionId.length > 0) {
            body.versionId = versionId;
        }
        if (sendEmail) {
            body.action = 'email'
        }
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotesversion`,
            bodyData: body
        }
        await doPostApiCall(data)
            .then(async (res: any) => {
                if (!res.error && res.result) {
                    if (!versionId) {
                        localStorage.setItem("versionId", res.result?.version?._id);
                        setVersion(res.result?.version?._id);
                    }
                    setLoader(false);
                    setLoading(false);
                    dispatch(setNotificationOpen({ message: "success", subText: `Net information saved successfully`, alertType: "success", borderClass: "success" }));
                    if (type && type === 'continue' && res.result.order) {
                        setLoading(true);
                        let orderObj = { ...res.result.order }

                        let reqBody: any = {
                            quoteId: orderObj?.quoteId?._id ? orderObj?.quoteId?._id : orderObj?.quoteId,
                            billingAddress: orderObj?.billingAddress,
                            shippingAddress: orderObj?.shippingAddress,
                            willCall: orderObj?.willCall,
                            salesId: orderObj?.saleApply?.saleId || null,
                            saleDiscountAmount: orderObj?.calPrice?.saleDiscountAmount,
                            appliedUserCoupon: orderObj?.appliedUserCoupon,
                        }
                        await createOrderData(reqBody);
                    }

                }
                else {
                    setLoader(false)
                    dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while updating data`, alertType: "error", borderClass: "error" }));
                }
            })
            .catch((err: any) => {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while updating data`, alertType: "error", borderClass: "error" }));
                setLoader(false)
            })

    }

    const saveQuoteVersion = async (type: string, sendEmail?: boolean) => {
        setShowSoftAlert(false);
        if (editorMemo?.getObjects()?.length > 0) {
            if (type && type === 'continue') {
                setLoading(true)
            }
            else {
                setLoader(true);
            }
            const jsonObj = editorMemo.toJSON(propertiesToInclude);
            const cavasOutput = JSON.stringify(jsonObj, null, "\t");
            let body: any = {
                userId: quoteData.userId?._id,
                quoteId: quoteData._id,
                canvasData: cavasOutput,
            }
            if (selectedPanel.type === 'rope_support') {
                await saveNet(body, type);
                return;
            }

            let canvasImageUrl = await getCanvasImageData();
            body.adminSupports = adminSupports;
            await uploadFileData(canvasImageUrl).then(async (res: any) => {
                if (res.result[0].key) {
                    body.diagram = {
                        key: res.result[0].key,
                        name: res.result[0].originalname,
                        mimetype: res.result[0].mimetype,
                        location: res.result[0].location,
                        size: res.result[0].size,
                    }

                    await saveVersionApiCall(body, type, sendEmail)

                }

            }).catch((err: any) => {
                console.log(err);
            })

        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `No Design found to save`, alertType: "error", borderClass: "error" }));

        }
    }

    const isSupportAdded = () => {
        if (selectedPanel?.type === 'rope_support') {
            if (adminSupports?.length > 0) {
                return true
            }
            else {
                return false
            }
        }
        else {
            let supportArr: any = []
            for (let i = 0; i < itemArr.length; i++) {
                if (itemArr[i].support?.length > 0) {
                    let prevArr = itemArr[i].support.filter((obj: any) => { return (obj?.id?._id ? obj?.id?._id : obj.id) === ropeSId && obj.quantity > 0 })
                    supportArr = [...supportArr, ...prevArr]
                }
            }
            if (supportArr?.length > 0) {
                return true
            }
            else {
                return false
            }
        }

    }

    const saveDataAndNext = async (type?: string) => {
        if (editorMemo?.getObjects()?.length > 0) {
            if (deleteMode) {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Close delete mode to save data`, alertType: "error", borderClass: "error" }));
            }
            else {
                if (isSupportAdded()) {
                    // saveQuoteVersion(type);
                    setSaveType(type);
                    setShowEmailAlert(true);
                }
                else {
                    setShowSoftAlert(true);

                }
            }


        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `No Design found to save`, alertType: "error", borderClass: "error" }));
        }
    }




    const lockOrUnlockCanvas = () => {
        setLockCanvas(!lockCanvas);
        editorMemo.selection = lockCanvas;
        let polygon = editorMemo.getObjects('polygon')[0];
        if (!lockCanvas) {
            if (selectedPanel && selectedPanel?.type === 'rope_support') {
                displayDimentions()
            }
            else {
                addAdditionalDataForWire();
            }
        }
        editorMemo.forEachObject((obj: any) => {
            obj.selectable = lockCanvas;
            obj.evented = lockCanvas;
            disableLines(obj);
            if (selectedPanel && selectedPanel?.type === 'rope_support') {
                if (lockCanvas) {
                    if (obj.objectType === 'temp') {
                        editorMemo.remove(obj);
                    }
                    if (obj.objectType === 'netname' || obj.objectType === 'netValue' || obj.objectType === 'manufacturer-tag') {
                        obj.set({ visible: false })
                    }
                }
            }
            else {
                if (obj.objectType === 'temp') {
                    obj.set({ visible: !lockCanvas })
                    console.log("obj", obj)
                }
                if (obj.type === "group") {
                    let manufacturerTag = obj.getObjects()?.find((obj: any) => { return obj.objectType === 'manufacturer-tag' });
                    if (manufacturerTag) {
                        manufacturerTag.set({ visible: !lockCanvas });
                    }
                }

            }
        });
        editorMemo.requestRenderAll();

    }
    const disableLines = (obj: any) => {
        if (obj.type === 'line' || obj.type === 'polyline') {
            obj.set({ selectable: false, evented: false })
        }
    }


    const addRopeSupport = () => {
        let polygon = editorMemo.getObjects('polygon')[0];
        if (polygon && polygon.objectType === 'net') {
            let boundingRect = polygon.getBoundingRect();

            let supports = editorMemo?.getObjects('triangle')?.filter((obj: any) => { return obj.objectType === 'support' && obj.addedBy === 'admin' });
            let obj = {
                originX: 'center',
                originY: 'center',
                addedBy: 'admin',
                stroke: '#FBA905',
                strokeWidth: 5,
                strokeDashArray: [5, 5],
                selectable: false,
                evented: false,
                objectType: 'support_line',
                id: '',
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
                name: ropeSupport?.name

            }
            if (supports?.length > 0) {
                // Draw a red dashed vertical line from the clicked point to the bounding rectangle's height
                supports.forEach((supp: any) => {
                    obj.id = `support_line-${supp.id}`
                    const points = [
                        { x: supp.left, y: supp.top },
                        { x: supp.left, y: boundingRect.top + boundingRect.height }
                    ];
                    const line = new fabric.Polyline(points, obj);
                    editorMemo.add(line);
                    supp.set({ lockMovementX: true, lockMovementY: true })

                })
                setAddSupport(false);
                setAddRope(false);
                editorMemo.requestRenderAll();
            }

        }
    }

    const displayDimentions = () => {
        let polygon = editorMemo.getObjects('polygon')[0];
        let boundingRect = polygon.getBoundingRect();
        //Draw the bounding rectangle around the polygon
        let obj = {
            left: boundingRect.left,
            top: boundingRect.top,
            width: boundingRect.width,
            height: boundingRect.height,
            fill: 'transparent',
            stroke: 'gray',
            strokeDashArray: [5, 5],
            selectable: false,
            evented: false,
            objectType: 'temp'
        }
        const boundingBox = new fabric.Rect(obj);
        editorMemo.add(boundingBox);
        showOrAddManufacturerTag(polygon);
        addAdditionalDataForRope(polygon, boundingRect);
        showDistance();
    }
    const getNetImage = async (name: string) => {
        let polygon = editorMemo.getObjects('polygon')[0];
        let boundingRect = polygon.getBoundingRect();
        if (editor) {
            const options = {
                format: 'png',
                left: boundingRect.left - 50,
                top: boundingRect.top - 50,
                width: boundingRect.width + 105,
                height: boundingRect.height + 105,
                multiplier: 2,
                enableRetinaScaling: true
            }

            // Convert the sectionCanvas to an image URL
            if (!lockCanvas) {
                await lockOrUnlockCanvas();
            }
            const sectionImageURL = editorMemo.toDataURL(options);
            const res: Response = await fetch(sectionImageURL);
            const blob: Blob = await res.blob();
            return new File([blob], name, { type: 'image/png' });
        }
        else return null

    }
    const onAddSupport = (status: any) => {
        if (status) {
            if (selectedPanel?.type === 'rope_support') {
                setAddRope(true);
                setAddLine(false);
            }
            else {
                setAddRope(false);
                setAddLine(true);
            }
        }
        else {
            setAddRope(false);
            setAddLine(false);
        }
        setLinePoints([]);  // this as setLinePoints([]); not worlong on rerender canvas function
    }

    function calculateDistance(y1: number, y2: number): number {
        return Math.abs(y2 - y1);
    }

    const isOnSameYAxis = (point: any, referencePoints: any[]) => {
        return referencePoints.some(refPoint =>
            Math.abs(refPoint.top - point.top) <= 20
        );
    };
    const findNearestLeftPoint = (point: any, arr: any[]) => {
        let nearestLeftRect: any;
        let minDistance = Number.MAX_VALUE;
        arr.forEach((rect: any) => {
            if (rect.left < point.left) {
                const distance = Math.sqrt(
                    Math.pow(rect.left - point.left, 2) + Math.pow(rect.top - point.top, 2)
                );

                if (distance < minDistance && (((distance * pxValue) / 12) > 1)) {
                    minDistance = distance;
                    nearestLeftRect = rect;
                }
            }
        });

        return nearestLeftRect;
    }

    const findNearestRightPoint = (point: any, arr: any[]) => {
        let nearestRightRect: any;
        let minDistance = Number.MAX_VALUE;
        arr.forEach((rect: any) => {
            if (rect.left > point.left) {
                const distance = Math.sqrt(
                    Math.pow(rect.left - point.left, 2) + Math.pow(rect.top - point.top, 2)
                );
                if (distance < minDistance && (((distance * pxValue) / 12) > 1)) {
                    minDistance = distance;
                    nearestRightRect = rect;
                }
            }
        });

        return nearestRightRect;
    }


    const showDistance = () => {
        let polygon = editorMemo.getObjects('polygon')?.[0]
        const points = polygon.get('points');
        let allCorners = points.map((point: fabric.Point) => { return { left: point.x, top: point.y } })
        let adminSupports = editorMemo.getObjects()?.filter((data: any) => { return (data.objectType === 'support' && data.addedBy === 'admin') });
        let allSupports = [...allCorners, ...adminSupports]
        const pointOnYAxis = allSupports.filter((point: fabric.Point) => isOnSameYAxis(point, adminSupports));
        const options = {
            fontSize: 16,
            selectable: false,
            originX: 'center',
            originY: 'center',
            objectType: 'temp',
            fill: 'black'
        }
        let arr: any = [];
        adminSupports.forEach((support: any) => {
            let leftData = findNearestLeftPoint(support, pointOnYAxis);
            let rightData = findNearestRightPoint(support, pointOnYAxis);
            if (leftData) {
                let obj: any = {
                    left: leftData.left,
                    top: leftData.top,
                    supportLeft: support.left,
                    supportTop: support.top
                }
                obj.distance = pointDistance({ x: support.left, y: support.top }, { x: leftData.left, y: leftData.top });
                arr.push(obj);
            }
            if (rightData) {
                let obj: any = {
                    left: rightData.left,
                    top: rightData.top,
                    supportLeft: support.left,
                    supportTop: support.top
                }
                obj.distance = pointDistance({ x: support.left, y: support.top }, { x: rightData.left, y: rightData.top });
                arr.push(obj);
            }
            return arr;
        });
        const threshold = 20
        var filteredArr = arr.reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => (obj.left === o.left || (Math.abs(obj.left - o.left) <= threshold)))) {
                unique.push(o);
            }
            return unique;
        }, []);

        if (filteredArr.length > 0) {
            filteredArr.forEach((item: any) => {
                let obj = {
                    left: (item.supportLeft + item.left) / 2,
                    top: (item.supportTop + item.top) / 2 - 10
                }
                // let sortedDistance = Math.round(Math.abs(Number((item.distance / 12))))
                let sortedDistance = Math.round(Number((item.distance / 12)));
                const textDistance = new fabric.Text(`${sortedDistance} ft.`, { ...options, ...obj });
                editorMemo.add(textDistance);
            });
        }
        editorMemo.requestRenderAll();

    }
    const getSupports = () => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/items?page=1&dataPerPage=100`
        }
        doGetApiCall(data).then((res: any) => {
            if (!res.error) {
                let ropeIdx = res.result.findIndex((item: any) => item._id === ropeSId);
                if (ropeIdx > -1) {
                    setRopeSupport(res.result[ropeIdx]);
                }
                let wireIdx = res.result.findIndex((item: any) => item._id === wireSId);
                if (wireIdx > -1) {
                    setWireSupport(res.result[wireIdx]);
                }
                let zipIndex = res.result.findIndex((item: any) => item._id === zipSId);
                if (zipIndex > -1) {
                    setZipSupport(res.result[zipIndex]);
                }
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
            }
        })
            .catch((err: any) => {
                console.error(err)
            })
    }

    useEffect(() => {
        if (editorMemo?.getObjects().length > 0) {
            setAddSupport(true);
            if (editorMemo?.getObjects('triangle').length > 0) {
                setEnableRopeSupport(true);
            }
            else {
                setEnableRopeSupport(false);
            }
        }
        else {
            setAddSupport(false);
        }
        if (selectedPanel?.type === 'wire_support') {
            let wireSupports = editorMemo?.getObjects('line').filter((obj: any) => { return obj.supportId === wireSId });
            if (wireSupports.length > 0) {
                setShowDeleteLine(true);
            }
            else {
                setShowDeleteLine(false);
                toggleDeleteMode(false);
            }

        }
    }, [editor])



    const closeSoftModal = () => {
        setShowSoftAlert(false);
        setShowSave(true);
    }
    const showOrAddManufacturerTag = (polygon: any) => {
        let flagRect: any = {}
        if (selectedPanel?.type === 'rope_support') {
            let manufacturerTag = editorMemo.getObjects()?.find((obj: any) => { return obj.objectType === 'manufacturer-tag' });
            if (manufacturerTag) {
                flagRect = manufacturerTag;
                flagRect.set({ visible: true });
            }
            else {
                flagRect = getManufacturerTag(polygon);
                flagRect.set({ visible: true });
                editorMemo.add(flagRect);
            }
        }
        editorMemo.renderAll();

    }
    const addAdditionalDataForRope = (data: any, boundingRect: any) => {
        let allObj = editorMemo.getObjects();
        let netname = allObj?.find((obj: any) => { return obj.objectType === 'netname' });
        let netValue = allObj?.find((obj: any) => { return obj.objectType === 'netValue' });
        let nameObj = {
            fill: '#E81A20',
            visible: true,
            left: data.aCoords.tl.x,
            top: boundingRect.top - 30,
            originX: 'center',
            originY: 'center'
        }
        if (netname) {
            netname.set(nameObj)
        }
        let valueObj = {
            visible: true,
            left: data.aCoords.tr.x,
            top: boundingRect.top - 30,
            originX: 'center',
            originY: 'center'
        }
        if (netValue) {
            netValue.set(valueObj)
        }
        const bottomCenterX = (data.aCoords.bl.x + data.aCoords.br.x) / 2;
        const bottomCenterY = (data.aCoords.bl.y + data.aCoords.br.y) / 2;
        let textObject = {
            fontSize: 18,
            fontFamily: 'helvetica',
            fill: '#E81A20',
            selectable: false,
            evented: false,
            objectType: 'temp',
            left: bottomCenterX,
            top: bottomCenterY + 30,
            originX: 'center',
            originY: 'center'
        }
        let description = selectedData?.net?.description?.length <= 50 ? selectedData?.net?.description : selectedData?.net?.description?.substring(0, 50)
        const text = new fabric.Text(`Module: ${data.moduleName}\nNet: ${description}`, textObject)
        editorMemo.add(text);
    }

    const addAdditionalDataForWire = () => {
        let groups = editorMemo.getObjects('group');
        if (groups?.length > 0) {
            groups.forEach((group: any) => {
                group.setCoords();
                let polygon = group.getObjects('polygon')[0]
                if (polygon) {
                    polygon.setCoords();
                    const bottomCenterX = group?.oCoords?.mb?.x;
                    const bottomCenterY = group?.oCoords?.mb?.y;
                    let textObject = {
                        fontSize: 18,
                        fontFamily: 'helvetica',
                        textBackgroundColor: '#c88183',
                        fill: 'white',
                        selectable: false,
                        evented: false,
                        objectType: 'temp',
                        left: bottomCenterX,
                        top: bottomCenterY - 20,
                        originX: 'center',
                        originY: 'center'
                    }
                    const text = new fabric.Text(` ${polygon.name} `, textObject)
                    editorMemo.add(text);

                }
            })
        }
    }

    // Function to toggle delete mode and show/hide delete icons
    const toggleDeleteMode = (deleteMode: any) => {
        setDeleteMode(deleteMode);
        if (editor) {
            if (deleteMode) {
                // Show delete icons
                editorMemo.getObjects('line').forEach((lineObj: any) => {
                    const line = lineObj as fabric.Line;
                    const midX = (line.x1! + line.x2!) / 2;
                    const midY = (line.y1! + line.y2!) / 2;
                    let angle = Math.atan2(Math.abs(line.y1! - line.y2!), Math.abs(line.x1! - line.x2!));
                    let obj = {
                        left: (midX + line.x1!) / 2,
                        top: (midY + line.y1!) / 2,
                        fontSize: 15,
                        selectable: false,
                        hoverCursor: 'pointer',
                        id: 'deleteIcon',
                    }
                    const deleteIcon = new fabric.Text('❌', obj);

                    deleteIcon.on('mousedown', () => deleteLine(line, deleteIcon));

                    editorMemo.add(deleteIcon);
                });
            } else {
                // Remove all delete icons from the canvas
                const deleteIcons = editorMemo.getObjects('text').filter((obj: any) => obj.id === 'deleteIcon');
                deleteIcons.forEach((icon: any) => editorMemo.remove(icon));
            }

            editorMemo.renderAll();
        }
    };

    // Function to delete a specific line and its associated delete icon
    const deleteLine = async (line: fabric.Line | any, deleteIcon: fabric.Text) => {
        editorMemo.remove(line);
        editorMemo.remove(deleteIcon);
        let textObj = editorMemo.getObjects('text')?.find((obj: any) => obj.id === `wire-${line.id}`);
        if (textObj) {
            editorMemo.remove(textObj);
        }
        editorMemo.renderAll();

        if (editorMemo.getObjects('line').filter((obj: any) => obj.supportId === wireSId).length === 0) {
            setShowDeleteLine(false);
        }
        await createOrUpdateAdminSupportArr(line?.length, 'remove')
    };

    const closeEmailModal = () => {
        setShowEmailAlert(false);
        setSaveType('');
    }

    const handleSaveVersion = async (sendEmail: boolean) => {
        setShowEmailAlert(false);
        await saveQuoteVersion(saveType, sendEmail);
    }
    return {
        pannelType,
        onDeleteSelection,
        showDelete,
        onReady,
        editorMemo,
        editor,
        clearCanvas,
        updateZoom,
        quoteData,
        setQuoteData,
        selected,
        selectionType,
        enableDisableClickThrough,
        spanning,
        resetZoom,
        handleUndo,
        handleRedo,
        netData: netInfo,
        loader,
        netItems,
        cancelNetCreation: reRenderCanvas,
        handleSaveCanvasData,
        openSupportConfirmModal,
        handleAddtype,
        handleClearPanelType,
        showResetModal,
        handleResetModal,
        addLine,
        onAddSupport,
        linePoints,
        saveQuoteVersion,
        lockOrUnlockCanvas,
        lockCanvas,
        addRope,
        addRopeSupport,
        selectedPanel,
        addSupport,
        onAddNet,
        loading,
        enableRopeSupport,
        showSoftAlert,
        closeSoftModal,
        saveDataAndNext,
        showSave,
        selectedNet,
        showDeleteLine,
        toggleDeleteMode,
        deleteMode,
        showEmailAlert,
        closeEmailModal,
        handleSaveVersion
    }
}