import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { doGetApiCall, doPostApiCall, doPutApiCall } from "../../utils/ApiConfig";
import getLocalStorageData from "../../utils/getLocalStorageData";
import { useDispatch, useSelector } from "react-redux";
import { getDiagramRequest, getDiagramSuccess, getQuoteData, saveAllSupportList } from './quoteReducer/diagramReducer'
import text from '../../common/en_US.json'
import { useLocation, useNavigate } from "react-router";
import { useParams, useSearchParams } from 'react-router-dom';
import { saveAddressDetails, saveUserDetails } from "../authentication/userReducer/userReducer";
import { authenticationError, setNotificationOpen } from "../notificationReducer/NotificationReducer";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getInvoiceFailure, getInvoiceSuccess, getSalesOfferDetails, getTaxValue } from "./invoiceReducer/invoiceReducer";
import { useJwt } from 'react-jwt';
import { TokenGet } from '../../utils/tokenGet';
import { navigateToGn } from "../../utils/navigateToGn";

const getQueryStringValue = (key: string) => {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" +
                encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
};


export const useForQuote = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const [count, setCount] = useState(0)
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    const invoiceList = useSelector((state: any) => state?.invoiceReducer?.invoiceDetails)
    const taxValue = useSelector((state: any) => state?.invoiceReducer?.taxValue)
    // const allOrders = useSelector((state: any) => state?.myAccountReducer?.allOrders)
    const userAddressDetails = useSelector((state: any) => state?.userReducer?.addressDetails);
    const [address, setAddress]: any = useState();
    const [shippingAddr, setShippingAddr]: any = useState({});
    const [billingAddress, setBillingAddress]: any = useState({})
    const [shippingAddrObj, setshippingAddrObj]: any = useState();
    const [showBillingAsShipping, setShowBillingAsShipping] = useState(false);
    const [addressObj, setAddressObj]: any = useState();
    const [lantLng, setLatLng] = useState({ lat: 0, lng: 0 })
    const [queryVal, setQueryVal]: any = useState();
    const [quoteNotification, setQuoteNotification] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    const [userSupport, setUserSupport] = useState([]);
    let userDetails = getLocalStorageData("user")
    let { quoteId } = useParams();
    const [loading, setLoading]: any = useState(true);
    const [shipAddrData, setShipAddrData]: any = useState(null)
    const [billingAddData, setBillingAddData]: any = useState(null)
    const [showWillCall, setShowWillCall] = useState(false) //state for will call handling
    const [willCallAddress, setWillCallAddress] = useState({}) //state for will call address handling
    const [isPaymentIntent, setIsPaymentIntent] = useState(false);
    const [addressLoading, setAddressLoading]: any = useState(false);
    const [billingAdLoader, setBillingAdLoader] = useState(false);

    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    // const [consentCheck, setConsentCheck] = useState(false);

    const ropeSId = process.env.REACT_APP_PUBLIC_ropeSupportId ?? '';
    const wireSId = process.env.REACT_APP_PUBLIC_wireSupportId ?? '';

    // useEffect(() => {
    //     console.log(userAddressDetails, 'userAddressDetails #');
    // }, [userAddressDetails]);
    const [quoteDetailsOpen, setQuoteDetailsOpen] = useState(false);
    const handleQuoteDetailsOpen = () => {
        setQuoteDetailsOpen(true);
    }
    const handleQuoteDetailsClose = () => {
        setQuoteDetailsOpen(false);
    }

    // const handleConsent = (data: any) => {
    //     if (data?.target?.checked) {
    //         setConsentCheck(true);
    //     } else {
    //         setConsentCheck(false);
    //     }
    // }

    // const updateConsentCheck = (data:boolean) => {
    //     setConsentCheck(data);   
    // }


    const getQuery = (e: any) => {
        setQueryVal(e.target.value)
    }

    const handlePageLoad = () => {
        setLoading(false)
    }

    let token = searchParams.get('link') || searchParams.get('token') || '';
    const { isExpired } = useJwt(token);

    useEffect(() => {
        if (searchParams.get('link') && process.env.REACT_APP_PUBLIC_GET_TOKEN) {
            const token = TokenGet(getQueryStringValue('link'), process.env.REACT_APP_PUBLIC_GET_TOKEN)
            localStorage.setItem('token', token)
        } else if (searchParams.get('token')) {
            localStorage.setItem('token', getQueryStringValue('token'))
        }
    }, [token]);

    useEffect(() => {
        if(invoiceList?.willCall){
            setShowWillCall(true);
        }
    }, [invoiceList]);

    const cleardata = () => {
        setLoading(false);
        localStorage.clear();
        dispatch({ type: "RESET_STATE" });
    };

    useEffect(() => {
        const userId = searchParams.get('userId');
        const quoteId = searchParams.get('quoteId');
        const driver: string = localStorage.getItem('driver') || '';
        if (!token && !userId && !quoteId) {
            cleardata();
        }
        else if (token && isExpired) {
            cleardata();
        }
        else {
            if (token) {
                cleardata();
                if (searchParams.get('link') && process.env.REACT_APP_PUBLIC_GET_TOKEN) {
                    let token = TokenGet(getQueryStringValue('link'), process.env.REACT_APP_PUBLIC_GET_TOKEN)
                    localStorage.setItem('token', token)
                }
            }
            getUserDetails()
        }
        localStorage.setItem("driver", driver);
    }, []);

    const getAddressObject = (address_components: any) => {
        const ShouldBeComponent: any = {
            street_number: ["street_number"],
            postal_code: ["postal_code"],
            street: ["street_address", "route"],
            province: [
                "administrative_area_level_1",
                "administrative_area_level_2",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            city: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4"
            ],
            country: ["country"]
        };

        let address: any = {
            street_number: "",
            postal_code: "",
            street: "",
            province: "",
            city: "",
            country: ""
        };

        address_components?.forEach((component: any) => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === "country") {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });

        // Fix the shape to match our schema
        address.address = address.street_number + " " + address.street;
        delete address.street_number;
        delete address.street;
        if (address.country === "US") {
            address.state = address.province;
            delete address.province;
        }
        return address;
    };

    const func = async () => {
        const geocodeObj =
            address &&
            address.value &&
            (await geocodeByPlaceId(address.value.place_id));
        setLatLng({ lat: geocodeObj?.length > 0 ? geocodeObj[0]?.geometry?.location?.lat() : 0, lng: geocodeObj?.length > 0 ? geocodeObj[0]?.geometry?.location?.lng() : 0 })
        const addressObject =
            geocodeObj && getAddressObject(geocodeObj[0].address_components);
        setAddressObj(addressObject);
    };

    useEffect(() => {
        func();
    }, [address]);

    const shippingAddressVal = (val: any) => {
        setShippingAddr(val)
    }

    const billingAddressVal = (val: any) => {
        setBillingAddress(val)
    }



    const clearAddress = () => {
        if (shippingAddr?.label?.length > 0 && !shippingAddr?.value?.place_id || count === 0) {
            setShipAddrData({ name: addShippingFormik?.values?.name, email: addShippingFormik?.values?.email, phoneno: addShippingFormik?.values?.phoneno, city: '', state: '', zipcode: '' })
            setShippingAddr({})
            setCount(count + 1)
            setShowBillingAsShipping(false)
        }
    }

    const handleShippingAddressChange = (data: any) => {
        if (!data?.target?.checked) {
            setShipAddrData({ name: '', email: '', phoneno: '' })
        }
        setShippingAddr({})
        setShowBillingAsShipping(!showBillingAsShipping)
    }

    const addShippingFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: shippingAddr ? shippingAddr?.label : userDetails?.address?.street,
            country: (userDetails?.country && showBillingAsShipping) ? userDetails?.country : shipAddrData?.country ? shipAddrData?.country : invoiceList?.shippingAddress?.country ? invoiceList?.shippingAddress?.country : 'US',
            city: shippingAddr?.city ? shippingAddr?.city : (userDetails?.address?.city && showBillingAsShipping) ? userDetails?.address?.city : shipAddrData?.city ? shipAddrData?.city : invoiceList?.shippingAddress?.city ? invoiceList?.shippingAddress?.city : '',
            state: shippingAddr?.state ? shippingAddr?.state : shippingAddrObj?.province ? shippingAddrObj?.province : (userDetails?.address?.state && showBillingAsShipping) ? userDetails?.address?.state : shipAddrData?.state ? shipAddrData?.state : invoiceList?.shippingAddress?.state ? invoiceList?.shippingAddress?.state : "",
            zipcode: shippingAddr?.postal_code ? shippingAddr?.postal_code : (userDetails?.address?.zipcode && showBillingAsShipping) ? userDetails?.address?.zipcode : shipAddrData?.zipcode ? shipAddrData?.zipcode : invoiceList?.shippingAddress?.zipcode ? invoiceList?.shippingAddress?.zipcode : "",
            name: (userDetails?.name && showBillingAsShipping) ? userDetails?.name : shipAddrData?.name ? shipAddrData?.name : invoiceList?.shippingAddress?.name ? invoiceList?.shippingAddress?.name : '',
            email: (userDetails?.email && showBillingAsShipping) ? userDetails?.email : shipAddrData?.email ? shipAddrData?.email : invoiceList?.shippingAddress?.email ? invoiceList?.shippingAddress?.email : "",
            phoneno: (userDetails?.phoneNo && showBillingAsShipping) ? userDetails?.phoneNo : shipAddrData?.phoneno ? shipAddrData?.phoneno : invoiceList?.shippingAddress?.phoneNo ? invoiceList?.shippingAddress?.phoneNo : "",
            lat: (userDetails?.phoneNo && showBillingAsShipping) ? userDetails?.address?.lat : shippingAddr?.lat ? shippingAddr?.lat : invoiceList?.shippingAddress?.lat ? invoiceList?.shippingAddress?.lat : "",
            lng: (userDetails?.phoneNo && showBillingAsShipping) ? userDetails?.address?.lng : shippingAddr?.lng ? shippingAddr?.lng : invoiceList?.shippingAddress?.lng ? invoiceList?.shippingAddress?.lng : "",
        },
        validationSchema: Yup.object({
            city: Yup.string().required("City is required").min(3, "City must be at least 3 characters")
                .max(50, "City must be less than 50 characters"),
            address: Yup.string().required("Address is required"),
            state: Yup.string().required("State is required").min(2, "State must be at least 2 characters")
                .max(50, "State must be less than 50 characters"),
            zipcode: Yup.string().required("Zipcode is required").min(5, "Zipcode must be at least 5 characters"),
            country: Yup.string().required("Country is required"),
            name: Yup.string().required("Name is required").min(3, "Name must be at least 3 characters")
                .max(50, "Name must be less than 50 characters"),
            email: Yup.string().required("Email is required").email("Email is invalid"),
            phoneno: Yup.string().required("Phone number is required").min(10, "Phone number must be at least 10 characters").matches((/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), 'Phone number is invalid')
        }),
        onSubmit: (values: any) => {
            setAddressLoading(true);
            // let data = {
            //     url: `${process.env.NEXT_PUBLIC_apiurl}/orders/${invoiceList?._id}`,
            //     bodyData: {
            //         shippingAddress: {
            //             name: values.name,
            //             email: values.email,
            //             phoneNo: values.phoneno,
            //             street: values.address,
            //             city: values.city,
            //             state: values.state,
            //             zipcode: values.zipcode
            //         },
            //     }
            // }
            // doPutApiCall(data)
            //     .then((res: any) => {
            //         createPayment(res?.result?.total)
            //     })
            addSupports(Number(searchParams.get('index')) ?? 0, values)
        }
    });

    const billingAddFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: billingAddress ? billingAddress?.label : "",
            country: billingAddData?.country ? billingAddData?.country : "US",
            city: billingAddress?.city ? billingAddress?.city : billingAddData?.city ? billingAddData?.city : "",
            state: billingAddress?.state ? billingAddress?.state : billingAddress?.province ? billingAddress?.province : billingAddData?.state ? billingAddData?.state : "",
            zipcode: billingAddress?.postal_code ? billingAddress?.postal_code : billingAddData?.zipcode ? billingAddData?.zipcode : "",
            lat: billingAddress?.lat ? billingAddress?.lat : userDetails?.billingAddress?.lat,
            lng: billingAddress?.lng ? billingAddress?.lng : userDetails?.billingAddress?.lng,
            name: billingAddData?.name ? billingAddData?.name : '',
            email: getLocalStorageData('email') ? getLocalStorageData('email') : getLocalStorageData('user')?.email || "",
            phoneno: billingAddData?.phoneno ? billingAddData?.phoneno : "",
            companyName: billingAddData?.companyName ? billingAddData?.companyName : ""
        },
        validationSchema: Yup.object({
            country: Yup.string().required("Country is required"),
            city: Yup.string().required("City is required").min(3, "City must be at least 3 characters")
                .max(50, "City must be less than 50 characters"),
            address: Yup.string().required("Address is required"),
            state: Yup.string().required("State is required").min(2, "State must be at least 2 characters")
                .max(50, "State must be less than 50 characters"),
            zipcode: Yup.string().required("Zipcode is required").min(5, "Zipcode must be at least 5 characters"),
            name: Yup.string().required("Name is required").min(3, "Name must be at least 3 characters")
                .max(20, "Name must be less than 50 characters"),
            email: Yup.string().required("Email is required").email("Email is invalid"),
            phoneno: Yup.string().min(10, "Phone number must be at least 10 characters").matches((/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), 'Phone number is invalid'),
            companyName: Yup.string(),
        }),
        onSubmit: (values: any) => {
            // console.log(values,'values ##');
            addBillingAddress(values)
        }
    });

    useEffect(() => {//This useEffect is used when there is no zipcode in the billing address and user manually types a zipcode, 
        //and after that if user tries to edit name then zipcode field value gets cleared because of re-render.
        setBillingAddress({ ...billingAddress, country: billingAddFormik?.values?.country, postal_code: (billingAddFormik?.values?.zipcode).toString() })
    }, [billingAddFormik?.values])

    useEffect(() => {
        setBillingAddData({ name: billingAddFormik?.values?.name, phoneno: billingAddFormik?.values?.phoneno, country: billingAddFormik?.values?.country, city: billingAddFormik?.values?.city, state: billingAddFormik?.values?.state, zipcode: billingAddFormik?.values?.zipcode })
    }, [billingAddFormik?.values])


    useEffect(() => {
        setShipAddrData({ name: addShippingFormik?.values?.name, email: addShippingFormik?.values?.email, phoneno: addShippingFormik?.values?.phoneno, country: addShippingFormik?.values?.country, city: addShippingFormik?.values?.city, state: addShippingFormik?.values?.state, zipcode: addShippingFormik?.values?.zipcode })
    }, [addShippingFormik?.values])

    const userDetailsGet = () => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(saveUserDetails(res?.result));
                    localStorage.setItem("user", JSON.stringify(res.result));
                    localStorage.setItem("userId", res.result?._id);
                }
            })
            .catch((err: any) => {
                console.error(err)
            })
    }

    const addBillingAddress = (values: any) => {
        setBillingAdLoader(true)
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`,
            bodyData: {
                address: {
                    name: values?.name,
                    email: values?.email,
                    phoneNo: values?.phoneno,
                    street: values?.address,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    lat: values?.lat,
                    lng: values?.lng,
                    country: values?.country
                },
                name: values?.name,
                phoneNo: values?.phoneno,
                country: values?.country,
                companyName: values?.companyName
            }
        }
        doPutApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setBillingAdLoader(false)
                    dispatch(setNotificationOpen({ message: "Billing Address added", subText: `Billing Address ${res?.message}`, alertType: "success", borderClass: "success" }));
                    userDetailsGet()
                } else {
                    dispatch(setNotificationOpen({ message: "Failed", subText: `${res?.message}`, alertType: "error", borderClass: "error" }));
                    setBillingAdLoader(false)
                }
            })
            .catch((err: any) => {
                setBillingAdLoader(false)
                console.error(err, "updateUser Failed *")
            })
    }

    const chnageShippingAddressValue = (data: any) => {
        setshippingAddrObj(data);
    }

    useEffect(() => {
        if (showBillingAsShipping) {
            setLatLng({ lat: userDetails?.address?.lat, lng: userDetails?.address?.lng })
            setShippingAddr({ ...shippingAddr, label: userDetails?.address?.street })
        }
        if (invoiceList?.shippingAddress?.street) {
            setLatLng({ lat: invoiceList?.shippingAddress?.lat, lng: invoiceList?.shippingAddress?.lng })
            setShippingAddr({ ...shippingAddr, label: invoiceList?.shippingAddress?.street })
        }
        else if (showBillingAsShipping === false) {
            // setLatLng({ lat: lantLng.lat, lng: lantLng.lng })
            addShippingFormik.resetForm()
            // setShippingAddr({ ...shippingAddr, label: '' })
        }
    }, [invoiceList, showBillingAsShipping])

    useEffect(() => {
        if (addShippingFormik?.dirty && showBillingAsShipping) {
            setShowBillingAsShipping(false)
        }
    }, [addShippingFormik?.dirty, showBillingAsShipping])

    const openAuthMessage = (alertType: string, message: string, subText: string, borderClass: string) => {
        if (alertType) {
            setQuoteNotification({ open: true, message: message, subText: subText, alertType: alertType, borderClass: borderClass });
            // dispatch({ type: "AUTHENTIOCATION_ERROR", payload: notificationObj });
        }
    };

    let userId = getLocalStorageData("userId") ? getLocalStorageData("userId") : getLocalStorageData("user")?._id;

    const changeAddress = (val: any) => {
        setAddress(val)
    }

    //address formik validation
    const addressFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: address ? address?.label : "",
            city: address?.city ? address?.city : "",
            state: address?.state ? address?.state : address?.province ? address?.province : "",
            zipcode: address?.postal_code ? address?.postal_code : "",
            lat: address?.lat ? address?.lat : userDetails?.address?.lat,
            lng: address?.lng ? address?.lng : userDetails?.address?.lng,
        },
        validationSchema: Yup.object({
            city: Yup.string().required("City is required").min(3, "City must be at least 3 characters")
                .max(50, "City must be less than 50 characters"),
            address: Yup.string().required("City is required"),
            state: Yup.string().required("State is required").min(2, "State must be at least 2 characters")
                .max(50, "State must be less than 50 characters"),
            zipcode: Yup.string().required("Zipcode is required").min(5, "Zipcode must be at least 5 characters")
        }),
        onSubmit: (values) => {
            address.lable !== "" && addEditAddress(values);
        }
    });

    // this function is used to add and edit address
    const addEditAddress = (info: any) => {
        let bodyData = {
            address: {
                street: info?.address,
                city: info?.city,
                state: info?.state,
                zipcode: info?.zipcode,
                lat: info?.lat,
                lng: info?.lng
            }
        }
        if (userId) {
            let data = {
                url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`,
                bodyData,
            }
            doPutApiCall(data)
                .then(async (res: any) => {
                    if (!res?.error) {
                        localStorage.setItem('userId', res?.result?._id)
                        localStorage.setItem("user", JSON.stringify(res?.result));
                        dispatch(saveUserDetails(res?.result));
                        dispatch(saveAddressDetails(res?.result?.address));
                        dispatch(setNotificationOpen({ message: "Update Successfully", subText: `${res?.message}`, alertType: "success", borderClass: "success" }))
                        let data = {
                            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes`,
                            bodyData: {
                                userId: userId,
                            }
                        }
                        await doPostApiCall(data)
                            .then((res: any) => {
                                if (!res.error) {
                                    navigate(`/quote/addquote?userId=${userId}&quoteId=${res.result?._id}&index=0`);
                                } else {
                                    dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
                                }
                            }).catch((err: any) => {
                                console.log(err, 'error');
                            })
                    }
                    else {
                        console.log("error");
                        dispatch(setNotificationOpen({ message: "Failed", subText: `${res?.message}`, alertType: "error", borderClass: "error" }));
                        // dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" } })

                    }
                })
                .catch((err: any) => {
                    console.log(err);
                })
        }
    }

    const getQuotes = () => {
        let quoteId = searchParams.get('quoteId') || '';
        dispatch(getDiagramRequest(true))
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes/${quoteId}`,
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(getDiagramSuccess(res?.result?.items));
                    dispatch(getQuoteData(res?.result));
                    dispatch(saveUserDetails(res?.result?.userId));
                    dispatch(saveAddressDetails(res?.result?.userId?.address));
                }
                else {
                    dispatch({ type: 'GET_DIAGRAM_FAILURE' })
                }
            })
            .catch((err: any) => {
                console.log(err, "<<-- err")
            })
    }


    //this is used for create user formik form validation
    const userForm = useFormik({
        initialValues: {
            name: "",
            email: "",
            phoneno: "",
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required").min(3, "Name must be at least 3 characters")
                .max(20, "Name must be less than 20 characters"),
            email: Yup.string().required("Email is required").email("Email is invalid"),
            phoneno: Yup.string().required("Phone number is required").min(10, "Phone number must be at least 10 characters").matches((/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/), 'Phone number is invalid'),
            password: Yup.string().min(8, "Must be 8 or more than 8 characters")
                .required("Password field is Required")
                .matches(
                    /\w/,
                    "Please enter valid password"
                ),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), ''], 'Password must match')
                .required("Confirm password field is required")
                .matches(
                    /\w/,
                    "Please enter valid password"
                ),
        }),
        onSubmit: (values) => {
            let data = {
                ...values
            }
            createUser(data)
        }
    });


    // this function is used for logIn
    const createUser = (item: any) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users`,
            bodyData: {
                name: item.name,
                email: item.email,
                phoneNo: item.phoneno,
                password: item?.password
            },
        }
        doPostApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    localStorage.setItem('userId', res?.result?._id)
                    localStorage.setItem("user", JSON.stringify(res?.result));
                    dispatch(saveUserDetails(res?.result));
                    dispatch(setNotificationOpen({ message: 'User created successfully', subText: `${res?.message}`, alertType: 'success', borderClass: 'success' }))
                    navigate('/quote/addaddress')
                } else {
                    openAuthMessage(
                        "error",
                        res.message,
                        text.userAlreadyExit,
                        "error"
                    );
                    dispatch(authenticationError({ message: res?.message, subText: `${text.userAlreadyExit}`, alertType: "error", borderClass: "error" }))
                    console.log('result')
                }
            })
            .catch((err: any) => {
                console.log(err, 'error');
            })
    }

    const getUserDetails = async () => {
        // console.log(location.pathname,"pathname getUserDetails ##");
        const path: string = window.location.href;
        setLoading(true)
        const token = getQueryStringValue('token') || localStorage.getItem("token") || '';
        // console.log(token, 'getUserDetails ##')
        if (token) {
            localStorage.setItem('token', token);
        }
        let userId = searchParams.get('userId') || localStorage.getItem("userId") || '';
        let quoteId = searchParams.get('quoteId') || '';
        if (searchParams.get('logedUserId') && searchParams.get('logedUserRole') && searchParams.get('logedUserToken')) {
            localStorage.setItem('logedUserId', searchParams.get('logedUserId') || '');
            localStorage.setItem('logedUserRole', searchParams.get('logedUserRole') || '');
            localStorage.setItem('logedUserToken', searchParams.get('logedUserToken') || '');
        }
        if (searchParams.get('orderId')) {
            localStorage.setItem('orderId', searchParams.get('orderId') || '');
        }

        if (userId?.length > 0) {
            let url = `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`
            let data = {
                url: url.replaceAll('"', '')
            }
            await userId && doGetApiCall(data)
                .then((res: any) => {
                    if (res.error) {
                        setLoading(false)
                        // navigate(`/`)
                    }
                    else {
                        dispatch(saveUserDetails(res?.result));
                        const tour = res.result?.tour || localStorage.getItem('driver')
                        localStorage.setItem("user", JSON.stringify(res.result));
                        localStorage.setItem("userId", res.result._id);
                        localStorage.setItem('driver', tour)

                        quoteId = searchParams.get('quoteId') || res.result.quoteId
                        if (location.pathname === '/quote/netsupport') {
                            navigate(`/quote/netsupport?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`)
                        }
                        else if (searchParams.get('payment')) {
                            navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)

                        }
                        else if (searchParams.get('paymentConfirm')) {
                            if (searchParams.get('offerValue')) {
                                navigate(`/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&offerValue=${searchParams.get('offerValue')}&salesId=${searchParams.get('salesId')}&tax=${searchParams.get('tax')}&taxrate=${searchParams.get('taxrate')}&total=${searchParams.get('total')}&index=${searchParams.get('index') ?? 0}&format=payment&paymentConfirm=true&payment_intent=${searchParams.get('payment_intent')}`)
                            } else {
                                navigate(`/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&index=${searchParams.get('index') ?? 0}&format=payment&paymentConfirm=true&payment_intent=${searchParams.get('payment_intent')}`)
                            }
                        }
                        else if (quoteId && quoteId?.trim()?.length > 0 && searchParams.get('role') === 'admin') {
                            localStorage.setItem("role", 'admin');
                            console.log('admin switch #');
                            navigate(`/quote/editor?userId=${userId}&quoteId=${quoteId}&role=admin`)

                        }
                        // else if (quoteId && quoteId?.trim()?.length > 0) {
                        // navigate(`/quote/editor?userId=${userId}&quoteId=${quoteId}`)
                        // else if (res.result.quoteId) {
                        //     navigate(`/quote/editor?userId=${res.result._id}&quoteId=${res.result.quoteId}`);
                        // }

                        else if (res.result?.quoteId?.length > 0 && path.includes("editor") && searchParams.get('page') !== 'address' && !path.includes("invoice")) {
                            console.log('else if (res.result?.quoteId?.length > 0 && path.includes("editor") && !path.includes(addshippingaddr)) $$');
                            navigate(`/quote/editor?userId=${res.result._id}&quoteId=${quoteId}`)
                        }
                        else {
                            // navigate(`/`) // magic link changes
                        }
                        setLoading(false)
                    }
                }).catch((error) => {
                    console.log(error);

                })
        }
        else {
            console.log("last else part $$");
            setLoading(false)
            // navigate(`/`)                 // Remove it for testing purpose 
        }
    }

    const getInvoiceDetails = () => {
        if (userId) {
            let data = {
                url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders?quoteId=${searchParams.get('quoteId')}`,
            };

            doGetApiCall(data)
                .then((res: any) => {
                    if (!res.error) {
                        dispatch(getInvoiceSuccess(res.result));
                        // dispatch({ type: "GET_INVOICE_SUCCESS", payload: res.result });
                        dispatch({ type: "GET_MYACCOUNT_SUCCESS", payload: res.result });
                    }
                    else if (res.status === 401 || res.code === 'TOKEN_EXPIRED') {
                        // router.push(`/login`)
                        navigate('/login')
                    }
                })
                .catch((err) => {
                    console.log(err, "error");
                    dispatch(getInvoiceFailure());
                    dispatch({ type: 'GET_MYACCOUNT_FAILURE', payload: err });
                });
        }
    }

    const getSupports = () => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/items?page=1&dataPerPage=50`
        }
        doGetApiCall(data).then((res: any) => {
            if (!res.error) {
                // dispatch({ type: "GET_SUPPORTS_SUCCESS", payload: res.result })
                let list = res.result;
                if (res.result?.length > 0) {
                    list = res.result?.filter((item: any) => item?._id !== ropeSId && item?._id !== wireSId)
                }
                dispatch(saveAllSupportList(list));
                setUserSupport(list);
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
            }
        })
            .catch((err: any) => {
                console.error(err)
            })
    }

    const addSupports = (index: number, values: any) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders`,
            bodyData: {
                userId: userId,
                // quoteId: router.query.quoteId,
                quoteId: searchParams.get('quoteId'),

                billingAddress: {
                    name: userDetails?.name,
                    email: userDetails?.email,
                    phoneNo: userDetails?.phoneNo,
                    street: userDetails?.address?.street,
                    city: userDetails?.address?.city,
                    state: userDetails?.address?.state,
                    zipcode: userDetails?.address?.zipcode,
                    lat: userDetails?.address?.lat,
                    lng: userDetails?.address?.lng,
                    country: userDetails?.address?.country
                },
                shippingAddress: {
                    name: values?.name,
                    email: values?.email,
                    phoneNo: values?.phoneno,
                    street: values?.address ? values?.address : queryVal,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    lat: values?.lat,
                    lng: values?.lng,
                    country: values?.country
                },
                willCall: showWillCall

            }
        }
        doPostApiCall(data).then((res: any) => {
            if (!res.error) {
                localStorage.setItem('orderId', res?.result?._id);
                setAddressLoading(false);
                dispatch(setNotificationOpen({ message: "Created Successfully", subText: `${res.message}`, alertType: "success", borderClass: "success" }));
                // navigate(`/quote/invoice?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`);
                navigate(`/quote/applyoffer?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`);
            }
            else {
                setAddressLoading(false);
                console.log("error");
                dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
            }
        })
            .catch((err: any) => {
                console.error(err)
            })
    }


    const updateOrder = (values?: any, isOfferApplied?: any, salesId?: any, gotoNextPage?: any) => {
        // if (consentCheck) {
        setIsUpdateLoading(true)
        const orderId = getLocalStorageData('orderId');
        let data: any = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders/${orderId}`,
            bodyData: {
                userId: userId,
                quoteId: searchParams.get('quoteId'),

                billingAddress: {
                    name: userDetails?.name,
                    email: userDetails?.email,
                    phoneNo: userDetails?.phoneNo,
                    street: userDetails?.address?.street,
                    city: userDetails?.address?.city,
                    state: userDetails?.address?.state,
                    zipcode: userDetails?.address?.zipcode,
                    lat: userDetails?.address?.lat,
                    lng: userDetails?.address?.lng,
                    country: userDetails?.address?.country
                },
                shippingAddress: {
                    name: values?.name,
                    email: values?.email,
                    phoneNo: values?.phoneno,
                    street: values?.street ? values?.street : queryVal,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    lat: values?.lat,
                    lng: values?.lng,
                    country: values?.country
                },
                willCall: showWillCall,
                orderStatus: "approvalpending",  // user is requesting for order approval for admin
                // userConsentCheck: consentCheck

            }
        }
        if (isOfferApplied && salesId) data.bodyData.salesId = salesId;

        doPutApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    // this line will redirect to myorder list page if user has token 
                    let logedUserId = searchParams.get('logedUserId') || localStorage.getItem("logedUserId") || '';
                    let logedUserRole = searchParams.get('logedUserRole') || localStorage.getItem("logedUserRole") || '';
                    window.location.replace(navigateToGn(`?userId=${userId}&token=${getLocalStorageData("token")}&index=0&logedUserId=${logedUserId}&logedUserRole=${logedUserRole}`))
                    // token && window.location.replace(navigateToGn("myaccount",searchParams.get("userId") ?? userId,searchParams.get("quoteId") ?? quoteId,searchParams.get("index"),token))

                    // if (gotoNextPage) gotoNextPage(); // comment out for new net create flow added 
                }
                else {
                    setIsUpdateLoading(false)
                }
            })
            .catch((err: any) => {
                console.log(err)
                setIsUpdateLoading(false)
            })
        // } else {
        //     dispatch(setNotificationOpen({ message: "Please read and accept this consent first", subText: `Please read and accept this consent first`, alertType: "error", borderClass: "error" }));
        // }
    }

    const continueToInvoice = (discountPrice: string, salesId: string) => {
        if (Number(discountPrice) !== 0) {
            navigate(`/quote/invoice?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&offerValue=${discountPrice}&salesId=${salesId}&index=${searchParams.get('index') ?? 0}`);
        } else {
            navigate(`/quote/invoice?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`);
        }
    }

    const handleWillCall = (data: any) => {
        if (!data?.target?.checked) {
            setWillCallAddress({})
        }
        setWillCallAddress({
            city: "Jackson",
            state: "California",
            address: "The SunnyCal Solar Store 795 S. Hwy 49, Jackson, CA ",
            zipcode: "95642",
            country: "US",
            phoneno: "209-464-6100",
            email: "sales@solargolfnet.com"
        })
        setShowWillCall(!showWillCall)
    }

    const createPayment = (amount: string, quoteId?: string) => {
        console.log('createPayment #');
        if (!isPaymentIntent) {
            setAddressLoading(true)
            let data = {
                url: `${process.env.REACT_APP_PUBLIC_apiurl}/stripe/payment`,
                bodyData: {
                    amount: Number(Number(amount).toFixed(2)),
                }
            }
            doPostApiCall(data)
                .then((res: any) => {
                    if (!res?.error) {
                        setIsPaymentIntent(true);
                        setAddressLoading(false)
                        localStorage.setItem('client_secret', res.result?.client_secret)
                        if (searchParams.get('offerValue')) {
                            navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${quoteId || searchParams.get('quoteId')}&offerValue=${searchParams.get('offerValue')}&salesId=${searchParams.get('salesId')}&tax=${searchParams.get('tax')}&taxrate=${searchParams.get('taxrate')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)
                        } else {
                            navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${quoteId || searchParams.get('quoteId')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)
                        }
                    } else {
                        if (res?.code === 'TOKEN_EXPIRED') {
                            dispatch(setNotificationOpen({ message: "Failed", subText: `Session timed out. Please try logging in again.`, alertType: "error", borderClass: "error" }));
                            setTimeout(() => {
                                navigate(`${process.env.REACT_APP_PUBLIC_GN_URL}/login`)
                            }, 3000)
                        }
                        setAddressLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err, '<<-- err');
                    navigate(`${process.env.REACT_APP_PUBLIC_GN_URL}/login`)
                })
        } else {
            console.log(isPaymentIntent, localStorage.getItem('client_secret'), 'intent created #');
        }

    }

    /**
     * @author uplvikash
     * @method GET
     * @description This method is used to retrieve the sale information.
     */
    const getSaleDetails = () => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/sales/users`,
        };

        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(getSalesOfferDetails(res?.result));
                }
                else {
                    console.error(res.error);
                }
            })
            .catch((err) => {
                console.error(err, "error");
            });
    }

    /**
     * @author uplvikash
     * @method POST
     * @description This method is used to retrieve the tax information.
     */
    const getTax = (discountPrice: any) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders/tax/${getLocalStorageData('orderId')}`,
            bodyData: {
                discountAmount: Number(discountPrice)
            }
        }
        doPostApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(getTaxValue(res?.result));
                }
                else {
                    console.error(res.error);
                }
            })
            .catch((err) => {
                console.error(err, "error");
            });
    }

    return {
        addressFormik,
        changeAddress,
        address,
        userForm,
        quoteNotification,
        getUserDetails,
        getSupports,
        addSupports,
        getQuotes,
        getQuery,
        userSupport,
        loading,
        count,
        clearAddress,
        getInvoiceDetails,
        addShippingFormik,
        shippingAddressVal,
        shippingAddr,
        handleShippingAddressChange,
        showBillingAsShipping,
        getAddressObject,
        handleWillCall,
        showWillCall,
        willCallAddress,
        createPayment,
        addressLoading,
        handlePageLoad,
        billingAddFormik,
        billingAddressVal,
        billingAddress,
        quoteDetailsOpen,
        handleQuoteDetailsOpen,
        handleQuoteDetailsClose,
        billingAdLoader,
        getSaleDetails,
        updateOrder,
        getTax,
        continueToInvoice,
        isUpdateLoading,
        // consentCheck,
        // handleConsent,
        // updateConsentCheck
    }
}