import React from 'react'
import ButtonFieldInput from '../buttonField/Button'
import TextFieldInput from '../FormFields/TextFieldInput'
import PlacesAutoCompleteCustom from '../placesAutoComplete/PlacesAutoCompleteCustom'
import PhoneFieldInput from '../FormFields/PhoneFieldInput'
import EmailFieldInput from '../commonEmailField/EmailField'
import nextLogo from '../../../Images/addUser/Rarraow.svg'
import styles from './Index.module.css'
import SelectInputField from '../FormFields/SelectInputField'
import Text from '../../en_US.json';
import country from '../../country.json';

const BillingAddress = (props: any) => {

    const countryOption = country.map((state) => {
        return { label: state.name, value: state.code };
    });
    
    return (
        <div>
            <form onSubmit={props.billingAddFormik.handleSubmit} className={` border-2 border-[#F2F2F2] rounded-[10px] p-4 ${styles.billingCard}`}>
                <div className={`flex flex-col gap-5`}>
                    <p className="text-sm font-medium text-[#2F80ED]">Add New Address</p>
                    <TextFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                        placeholder={`Company Name`}
                        type={`text`}
                        textinputname={`companyName`}
                        id={`companyName`}
                        // extracls={`w-[16rem] sm:w-[30rem]`}
                        onChange={props.billingAddFormik.handleChange}
                        onBlur={props.billingAddFormik.handleBlur}
                        value={props.billingAddFormik.values.companyName}
                        error={
                            props.billingAddFormik.touched.companyName &&
                            Boolean(props.billingAddFormik.errors.companyName)
                        }
                        errText={
                            props.billingAddFormik.touched.companyName &&
                            props.billingAddFormik.errors.companyName
                        }
                        clickEnter={props.billingAddFormik.handleSubmit}
                    />
                    <TextFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                        placeholder={`Full Name`}
                        type={`text`}
                        textinputname={`name`}
                        id={`name`}
                        // extracls={`w-[16rem] sm:w-[30rem]`}
                        onChange={props.billingAddFormik.handleChange}
                        onBlur={props.billingAddFormik.handleBlur}
                        value={props.billingAddFormik.values.name}
                        error={
                            props.billingAddFormik.touched.name &&
                            Boolean(props.billingAddFormik.errors.name)
                        }
                        errText={
                            props.billingAddFormik.touched.name &&
                            props.billingAddFormik.errors.name
                        }
                        clickEnter={props.billingAddFormik.handleSubmit}
                    />
                    <div className={`flex mb-4`}>
                        <EmailFieldInput
                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-gn-white-10 rounded-[8px] addUserInputFieldsGlobal`}
                            placeholder={`Email id`}
                            type={`email`}
                            textinputname={`email`}
                            id={`email`}
                            disabled={true}
                            // extracls={`w-[16rem] sm:w-[30rem]`}
                            onChange={props.billingAddFormik.handleChange}
                            onBlur={props.billingAddFormik.handleBlur}
                            value={props.billingAddFormik.values.email}
                            error={
                                props.billingAddFormik.touched.email &&
                                Boolean(props.billingAddFormik.errors.email)
                            }
                            errText={
                                props.billingAddFormik.touched.email &&
                                props.billingAddFormik.errors.email
                            }
                            clickEnter={props.billingAddFormik.handleSubmit}
                        />

                        <PhoneFieldInput
                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-gn-white-10 !border !border-solid !border-[#E0E0E0] !rounded-lg !w-[15em] !ml-4 !h-[42px]`}
                            // textnewclass={``}
                            placeholder={`Phone Number`}
                            textinputname={`phoneno`}
                            // id={`phoneno`}
                            onChange={props.billingAddFormik.handleChange}
                            // onBlur={props.billingAddFormik.handleBlur}
                            value={props.billingAddFormik.values.phoneno}
                            error={
                                props.billingAddFormik.touched.phoneno &&
                                Boolean(props.billingAddFormik.errors.phoneno)
                            }
                            errorText={
                                props.billingAddFormik.touched.phoneno &&
                                props.billingAddFormik.errors.phoneno
                            }
                        // dataCy={'editProfile-input-phone'}
                        />
                    </div>

                </div>
                <div className={`flex flex-col gap-5`}>
                    <div>
                        <div className='autoField'>
                            <div onClick={props.clearAddress}>
                                <PlacesAutoCompleteCustom
                                    id={`address`}
                                    shippingAddressVal={props.billingAddressVal}
                                    getAddressObject={props.getAddressObject}
                                    shippingAddr={props.billingAddress}
                                />
                            </div>
                        </div>
                        <p className={`text-red-500 text-xs`}>{props.billingAddFormik.touched.address &&
                            props.billingAddFormik.errors.address}</p>
                    </div>
                    <div className={`flex flex-col md:flex-row gap-5 w-[16rem] sm:w-[31rem]`}>
                        
                        <TextFieldInput
                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                            placeholder={`City`}
                            textinputname={`city`}
                            id={`city`}
                            type={`text`}
                            onChange={props.billingAddFormik.handleChange}
                            onBlur={props.billingAddFormik.handleBlur}
                            value={props.billingAddFormik.values.city}
                            error={
                                props.billingAddFormik.touched.city &&
                                Boolean(props.billingAddFormik.errors.city)
                            }
                            errText={
                                props.billingAddFormik.touched.city &&
                                props.billingAddFormik.errors.city
                            }
                            clickEnter={props.billingAddFormik.handleSubmit}
                        />
                        <TextFieldInput
                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                            placeholder={`State`}
                            textinputname={`state`}
                            id={`state`}
                            type={`text`}
                            onChange={props.billingAddFormik.handleChange}
                            onBlur={props.billingAddFormik.handleBlur}
                            value={props.billingAddFormik.values.state}
                            error={
                                props.billingAddFormik.touched.state &&
                                Boolean(props.billingAddFormik.errors.state)
                            }
                            errText={
                                props.billingAddFormik.touched.state &&
                                props.billingAddFormik.errors.state
                            }
                            clickEnter={props.billingAddFormik.handleSubmit}
                        />
                    </div>
                    <div className={`flex flex-col md:flex-row gap-5 w-[16rem] sm:w-[31rem]`}>
                    
                        <TextFieldInput
                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                            placeholder={`Zip Code`}
                            textinputname={`zipcode`}
                            id={`zipcode`}
                            type={`text`}
                            onChange={props.billingAddFormik.handleChange}
                            onBlur={props.billingAddFormik.handleBlur}
                            value={props.billingAddFormik.values.zipcode}
                            error={
                                props.billingAddFormik.touched.zipcode &&
                                Boolean(props.billingAddFormik.errors.zipcode)
                            }
                            errText={
                                props.billingAddFormik.touched.zipcode &&
                                props.billingAddFormik.errors.zipcode
                            }
                            clickEnter={props.billingAddFormik.handleSubmit}
                        />
                        <SelectInputField
                            inputLabel={Text.CountrySelectTxt}
                            placeholder={Text.CountrySelectTxt}
                            selectoptions={countryOption}
                            textinputname='country'
                            onChange={props.billingAddFormik.handleChange}
                            displayEmpty={true}
                            value={props.billingAddFormik?.values.country}
                            error={
                                props.billingAddFormik.touched.country &&
                                Boolean(props.billingAddFormik.errors.country)
                            }
                            helperText={
                                props.billingAddFormik.touched.country &&
                                props.billingAddFormik.errors.country
                            }
                            clickEnter={props.billingAddFormik.handleSubmit}
                        />
                    </div>
                </div>
                <div className={`flex w-full justify-between`}>
                    <div className={`py-4`}>
                        <ButtonFieldInput
                            name="SAVE"
                            type="submit"
                            endIcon={<img src={nextLogo} width={18} height={18} alt="arrow" />}
                            // handleButton={}
                            // endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                            buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                            loading={props.billingAdLoader}
                            disabled={props.billingAdLoader}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BillingAddress