import { useEffect, useState } from "react";
import "./index.css";
import { FabricJSCanvas } from "fabricjs-react";
import SideNav from "../../common/commonSideNav/SideNav";
import { ForAssemblyEditor } from "./Hooks";
import SideAssemblyPanel from "../../common/commonSideNav/SideAssemblyPanel";
import Header from "../../common/commonHeader/Header";
import { AlignGuidelines } from "fabric-guideline-plugin";
import { useSearchParams } from "react-router-dom";
import { sideNavAssemblyData } from "../../common/commonSideNav/sideNavData";
import NavHeader from "../../common/header/Header"
import ConfirmationModal from "../../common/uiComponents/ConfirmationModal";
import EmailConfirmationModal from "../../common/uiComponents/EmailConfirmationModal";

const AssemblyEditor = () => {
  const {
    pannelType,
    onDeleteSelection,
    showDelete,
    onReady,
    editorMemo,
    editor,
    clearCanvas,
    updateZoom,
    quoteData,
    selected,
    selectionType,
    enableDisableClickThrough,
    spanning,
    resetZoom,
    handleUndo,
    handleRedo,
    netData,
    loader,
    cancelNetCreation,
    openSupportConfirmModal,
    handleSaveCanvasData,
    handleAddtype,
    handleClearPanelType,
    showResetModal,
    handleResetModal,
    addLine,
    onAddSupport,
    linePoints,
    saveQuoteVersion,
    lockOrUnlockCanvas,
    lockCanvas,
    addRope,
    addRopeSupport,
    selectedPanel,
    addSupport,
    onAddNet,
    loading,
    enableRopeSupport,
    showSoftAlert,
    closeSoftModal,
    saveDataAndNext,
    showSave,
    selectedNet,
    showDeleteLine,
    toggleDeleteMode,
    deleteMode,
    showEmailAlert,
    closeEmailModal,
    handleSaveVersion
  } = ForAssemblyEditor();
  const [searchParams] = useSearchParams();


  useEffect(() => {
    if (editor) {
      const guideline = new AlignGuidelines({
        canvas: editorMemo,
        aligningOptions: {
          lineColor: "red",
          lineWidth: 2,
          lineMargin: 2,
        },
      });

      guideline.init();
    }
  }, [editor]);


  const checkPlatform =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "Mobile"
      : "Desktop";

  const [selectedOption, setSelectedOption]: any = useState(null);



  return (
    <div className="flex flex-col h-full w-full mt-1.5 xl:mt-0">
      <NavHeader />

      <div className="mt-[56px] overflow-hidden" style={{ height: "calc(100vh - 56px)" }}>
        {checkPlatform === "Mobile" ? (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              marginTop: "24px",
              marginLeft: "5px",
            }}
          >
            * This website is best viewed on a larger screen for an optimal
            browsing experience.
          </p>
        ) : null}
        <Header
          handleDelete={onDeleteSelection}
          showDelete={showDelete}
          clearCanvas={clearCanvas}
          handleZoom={updateZoom}
          quoteData={quoteData}
          selected={selected}
          selectionType={selectionType}
          enableDisableClickThrough={enableDisableClickThrough}
          spanning={spanning}
          resetZoom={resetZoom}
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          loader={loader}
          checkPlatform={checkPlatform}
          cancelNetCreation={cancelNetCreation}
          openSupportConfirmModal={openSupportConfirmModal}
          handleSaveCanvasData={handleSaveCanvasData}
          netData={netData}
          editorMemo={editorMemo}
          assemblyEditor
          handleResetModal={handleResetModal}
          showResetModal={showResetModal}
          addLine={addLine}
          linePoints={linePoints}
          saveData={saveQuoteVersion}
          lockOrUnlockCanvas={lockOrUnlockCanvas}
          lockCanvas={lockCanvas}
          addRope={addRope}
          addSupport={onAddSupport}
          pannelData={selectedPanel}
          showAddSupport={addSupport}
          addRopeSupport={addRopeSupport}
          loading={loading}
          enableRopeSupport={enableRopeSupport}
          saveAndContinue={saveDataAndNext}
          showSoftAlert={showSoftAlert}
          closeSoftModal={closeSoftModal}
          showSave={showSave}
          showDeleteLine={showDeleteLine}
          toggleDeleteMode={toggleDeleteMode}
          deleteMode={deleteMode}
        />

        <div
          className={"h-full flex justify-between overflow-hidden"}
          style={{
            marginTop:
              checkPlatform === "Mobile" ? "81px !important" : "50px !important",
            height: "calc(100vh - 72px)"
          }}
        >
          <div className="mainSection-sidebar">

            <SideNav
              pannelType={pannelType}
              handleClick={handleAddtype}
              sideNavData={sideNavAssemblyData}
              assemblyEditor
              selectedStep={selectedOption}
              setselectedStep={setSelectedOption}
              pannelData={selectedPanel}
            />

            {pannelType?.length > 0 ?
              <SideAssemblyPanel
                handleClearPanelType={handleClearPanelType}
                pannelType={pannelType}
                draggable
                data={netData}
                onClick={onAddNet}
                pannelData={selectedPanel}
                selectedOption={selectedOption}
                selectedNet={selectedNet}

              /> : null}
          </div>
          <div className={`mainSection-editorSection`} id={"canvas-editor-container"}>
            <FabricJSCanvas className={`assemmbly-canvas`} onReady={onReady} />
          </div>
        </div>
      </div>
      <EmailConfirmationModal 
        modalText="Want to send email?"
        open={showEmailAlert}
        handleClose={closeEmailModal}
        handleSave={handleSaveVersion}
        DilogContent={`Do you want to send email confirmation to user?`}
        ConfirmBtn={"Yes"}
        // netData={props.netData}
        // loading={loading}
      />
    </div>
  );
};

export default AssemblyEditor;

